import React from 'react'
import { Create } from 'react-admin'

import Title from './MailTemplateTitle'
import MailTemplateInput from './MailTemplateInput'

const MailTemplateShow = (props) => (
  <Create title={<Title />} {...props}>
    <MailTemplateInput />
  </Create>
)

export default MailTemplateShow
