/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SimpleList,
  ReferenceField,
  TextField,
  SearchInput,
  BooleanField,
} from 'react-admin'

// import ActionTemplateEditEmbedded from './ActionTemplateEditEmbedded'

const ActionTemplateFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

const ActionTemplateBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
)

const ActionTemplateList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<ActionTemplateFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<ActionTemplateBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList primaryText={(record) => record.fullName} />
    ) : (
      <Datagrid rowClick={'show'} optimized>
        <TextField source="name" />
        <ReferenceField
          source="claimStateId"
          reference="claimState"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isInitialState" />
        <BooleanField source="isFinalState" />
        <TextField source="claimType" />
      </Datagrid>
    )}
  </List>
)

export default ActionTemplateList
