import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  SelectInput,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
} from 'react-admin'

import Title from './ContactTitle'

import NotesInput from '../shared/NotesInput'

const ContactEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Base">
        <TextInput source="fullName" />
        <TextInput source="email" />
        <TextInput source="phone" />
        <TextInput source="language" />
        <TextInput multiline source="notes" />
        <ReferenceInput
          source="acquisitionId"
          reference="acquisition"
          link="show"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput source="accountHolder" />
        <TextInput source="IBAN" />
      </FormTab>
      <FormTab label="Notes">
        <NotesInput />
      </FormTab>
    </TabbedForm>
  </Edit>
)

ContactEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default ContactEdit
