/*
 from https://github.com/piotrgorecki/i18n-currency-name/blob/e25002b38a62a591470be3bb0d6cd5c2b4c3999f/src/langs/en.json

 modified the following way:

  Object.entries(currencyList)
    .map(([id, name]) => ({
      id,
      name,NativeSelect
    }))
    .sort((a, b) => a.name.localeCompare(b.label)),

    afterwards prepended (so duplicated, they should be in the list twice)
      Euro and USD to the list
    */

export const currencyList = [
  {
    id: 'EUR',
    name: 'Euro',
  },
  {
    id: 'USD',
    name: 'US Dollar',
  },
  {
    id: null,
    name: '--------',
  },
  {
    id: 'AFN',
    name: 'Afghani',
  },
  {
    id: 'DZD',
    name: 'Algerian Dinar',
  },
  {
    id: 'ARS',
    name: 'Argentine Peso',
  },
  {
    id: 'AMD',
    name: 'Armenian Dram',
  },
  {
    id: 'AWG',
    name: 'Aruban Guilder',
  },
  {
    id: 'AUD',
    name: 'Australian Dollar',
  },
  {
    id: 'AZN',
    name: 'Azerbaijanian Manat',
  },
  {
    id: 'BSD',
    name: 'Bahamian Dollar',
  },
  {
    id: 'BHD',
    name: 'Bahraini Dinar',
  },
  {
    id: 'THB',
    name: 'Baht',
  },
  {
    id: 'PAB',
    name: 'Balboa',
  },
  {
    id: 'BBD',
    name: 'Barbados Dollar',
  },
  {
    id: 'BYR',
    name: 'Belarussian Ruble',
  },
  {
    id: 'BZD',
    name: 'Belize Dollar',
  },
  {
    id: 'BMD',
    name: 'Bermudian Dollar',
  },
  {
    id: 'VEF',
    name: 'Bolivar Fuerte',
  },
  {
    id: 'BOB',
    name: 'Boliviano',
  },
  {
    id: 'BRL',
    name: 'Brazilian Real',
  },
  {
    id: 'BND',
    name: 'Brunei Dollar',
  },
  {
    id: 'BGN',
    name: 'Bulgarian Lev',
  },
  {
    id: 'BIF',
    name: 'Burundi Franc',
  },
  {
    id: 'CAD',
    name: 'Canadian Dollar',
  },
  {
    id: 'CVE',
    name: 'Cape Verde Escudo',
  },
  {
    id: 'KYD',
    name: 'Cayman Islands Dollar',
  },
  {
    id: 'GHS',
    name: 'Cedi',
  },
  {
    id: 'XAF',
    name: 'CFA Franc',
  },
  {
    id: 'XOF',
    name: 'CFA Franc',
  },
  {
    id: 'XPF',
    name: 'CFP Franc',
  },
  {
    id: 'CLP',
    name: 'Chilean Peso',
  },
  {
    id: 'CNY',
    name: 'Chinese Yuan',
  },
  {
    id: 'COP',
    name: 'Colombian Peso',
  },
  {
    id: 'KMF',
    name: 'Comoro Franc',
  },
  {
    id: 'CDF',
    name: 'Congolese Franc',
  },
  {
    id: 'BAM',
    name: 'Convertible Marks',
  },
  {
    id: 'NIO',
    name: 'Cordoba Oro',
  },
  {
    id: 'CRC',
    name: 'Costa Rican Colon',
  },
  {
    id: 'HRK',
    name: 'Croatian Kuna',
  },
  {
    id: 'CUP',
    name: 'Cuban Peso',
  },
  {
    id: 'CZK',
    name: 'Czech Koruna',
  },
  {
    id: 'GMD',
    name: 'Dalasi',
  },
  {
    id: 'DKK',
    name: 'Danish Krone',
  },
  {
    id: 'MKD',
    name: 'Denar',
  },
  {
    id: 'DJF',
    name: 'Djibouti Franc',
  },
  {
    id: 'STD',
    name: 'Dobra',
  },
  {
    id: 'DOP',
    name: 'Dominican Peso',
  },
  {
    id: 'VND',
    name: 'Dong',
  },
  {
    id: 'XCD',
    name: 'East Caribbean Dollar',
  },
  {
    id: 'EGP',
    name: 'Egyptian Pound',
  },
  {
    id: 'ETB',
    name: 'Ethiopian Birr',
  },
  {
    id: 'EUR',
    name: 'Euro',
  },
  {
    id: 'FKP',
    name: 'Falkland Islands Pound',
  },
  {
    id: 'FJD',
    name: 'Fiji Dollar',
  },
  {
    id: 'GIP',
    name: 'Gibraltar Pound',
  },
  {
    id: 'HTG',
    name: 'Gourde',
  },
  {
    id: 'PYG',
    name: 'Guarani',
  },
  {
    id: 'GNF',
    name: 'Guinea Franc',
  },
  {
    id: 'GWP',
    name: 'Guinea-Bissau Peso',
  },
  {
    id: 'GYD',
    name: 'Guyana Dollar',
  },
  {
    id: 'HKD',
    name: 'Hong Kong Dollar',
  },
  {
    id: 'UAH',
    name: 'Hryvnia',
  },
  {
    id: 'HUF',
    name: 'Hungary Forint',
  },
  {
    id: 'ISK',
    name: 'Iceland Krona',
  },
  {
    id: 'INR',
    name: 'Indian Rupee',
  },
  {
    id: 'IRR',
    name: 'Iranian Rial',
  },
  {
    id: 'IQD',
    name: 'Iraqi Dinar',
  },
  {
    id: 'ILS',
    name: 'Israeli Sheqel',
  },
  {
    id: 'JMD',
    name: 'Jamaican Dollar',
  },
  {
    id: 'JPY',
    name: 'Japan Yen',
  },
  {
    id: 'JOD',
    name: 'Jordanian Dinar',
  },
  {
    id: 'KES',
    name: 'Kenyan Shilling',
  },
  {
    id: 'PGK',
    name: 'Kina',
  },
  {
    id: 'LAK',
    name: 'Kip',
  },
  {
    id: 'EEK',
    name: 'Kroon',
  },
  {
    id: 'KWD',
    name: 'Kuwaiti Dinar',
  },
  {
    id: 'MWK',
    name: 'Kwacha',
  },
  {
    id: 'AOA',
    name: 'Kwanza',
  },
  {
    id: 'MMK',
    name: 'Kyat',
  },
  {
    id: 'GEL',
    name: 'Lari',
  },
  {
    id: 'LVL',
    name: 'Latvian Lats',
  },
  {
    id: 'LBP',
    name: 'Lebanese Pound',
  },
  {
    id: 'ALL',
    name: 'Lek',
  },
  {
    id: 'HNL',
    name: 'Lempira',
  },
  {
    id: 'SLL',
    name: 'Leone',
  },
  {
    id: 'LRD',
    name: 'Liberian Dollar',
  },
  {
    id: 'LYD',
    name: 'Libyan Dinar',
  },
  {
    id: 'SZL',
    name: 'Lilangeni',
  },
  {
    id: 'LTL',
    name: 'Lithuanian Litas',
  },
  {
    id: 'LSL',
    name: 'Loti',
  },
  {
    id: 'MGA',
    name: 'Malagasy Ariary',
  },
  {
    id: 'MYR',
    name: 'Malaysian Ringgit',
  },
  {
    id: 'TMT',
    name: 'Manat',
  },
  {
    id: 'MUR',
    name: 'Mauritius Rupee',
  },
  {
    id: 'MZN',
    name: 'Metical',
  },
  {
    id: 'MXN',
    name: 'Mexican Peso',
  },
  {
    id: 'MDL',
    name: 'Moldovan Leu',
  },
  {
    id: 'MAD',
    name: 'Moroccan Dirham',
  },
  {
    id: 'NGN',
    name: 'Naira',
  },
  {
    id: 'ERN',
    name: 'Nakfa',
  },
  {
    id: 'NAD',
    name: 'Namibia Dollar',
  },
  {
    id: 'NPR',
    name: 'Nepalese Rupee',
  },
  {
    id: 'ANG',
    name: 'Netherlands Antillian Guilder',
  },
  {
    id: 'RON',
    name: 'New Leu',
  },
  {
    id: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    id: 'BTN',
    name: 'Ngultrum',
  },
  {
    id: 'KPW',
    name: 'North Korean Won',
  },
  {
    id: 'NOK',
    name: 'Norwegian Krone',
  },
  {
    id: 'PEN',
    name: 'Nuevo Sol',
  },
  {
    id: 'MRO',
    name: 'Ouguiya',
  },
  {
    id: 'TOP',
    name: "Pa'anga",
  },
  {
    id: 'PKR',
    name: 'Pakistan Rupee',
  },
  {
    id: 'MOP',
    name: 'Pataca',
  },
  {
    id: 'UYU',
    name: 'Peso Uruguayo',
  },
  {
    id: 'PHP',
    name: 'Philippine Peso',
  },
  {
    id: 'PLN',
    name: 'Polish Zloty',
  },
  {
    id: 'GBP',
    name: 'Pound Sterling',
  },
  {
    id: 'BWP',
    name: 'Pula',
  },
  {
    id: 'QAR',
    name: 'Qatari Rial',
  },
  {
    id: 'GTQ',
    name: 'Quetzal',
  },
  {
    id: 'ZAR',
    name: 'Rand',
  },
  {
    id: 'OMR',
    name: 'Rial Omani',
  },
  {
    id: 'KHR',
    name: 'Riel',
  },
  {
    id: 'MVR',
    name: 'Rufiyaa',
  },
  {
    id: 'IDR',
    name: 'Rupiah',
  },
  {
    id: 'RUB',
    name: 'Russian Ruble',
  },
  {
    id: 'RWF',
    name: 'Rwanda Franc',
  },
  {
    id: 'SHP',
    name: 'Saint Helena Pound',
  },
  {
    id: 'SVC',
    name: 'Salvadoran Colon',
  },
  {
    id: 'SAR',
    name: 'Saudi Riyal',
  },
  {
    id: 'RSD',
    name: 'Serbian Dinar',
  },
  {
    id: 'SCR',
    name: 'Seychelles Rupee',
  },
  {
    id: 'SGD',
    name: 'Singapore Dollar',
  },
  {
    id: 'SBD',
    name: 'Solomon Islands Dollar',
  },
  {
    id: 'KGS',
    name: 'Som',
  },
  {
    id: 'SOS',
    name: 'Somali Shilling',
  },
  {
    id: 'TJS',
    name: 'Somoni',
  },
  {
    id: 'LKR',
    name: 'Sri Lanka Rupee',
  },
  {
    id: 'SDG',
    name: 'Sudanese Pound',
  },
  {
    id: 'SRD',
    name: 'Surinam Dollar',
  },
  {
    id: 'SEK',
    name: 'Swedish Krona',
  },
  {
    id: 'CHF',
    name: 'Swiss Franc',
  },
  {
    id: 'SYP',
    name: 'Syrian Pound',
  },
  {
    id: 'TWD',
    name: 'Taiwan Dollar',
  },
  {
    id: 'BDT',
    name: 'Taka',
  },
  {
    id: 'WST',
    name: 'Tala',
  },
  {
    id: 'TZS',
    name: 'Tanzanian Shilling',
  },
  {
    id: 'KZT',
    name: 'Tenge',
  },
  {
    id: 'TTD',
    name: 'Trinidad and Tobago Dollar',
  },
  {
    id: 'MNT',
    name: 'Tugrik',
  },
  {
    id: 'TND',
    name: 'Tunisian Dinar',
  },
  {
    id: 'TRY',
    name: 'Turkish Lira',
  },
  {
    id: 'AED',
    name: 'UAE Dirham',
  },
  {
    id: 'UGX',
    name: 'Uganda Shilling',
  },
  {
    id: 'USD',
    name: 'US Dollar',
  },
  {
    id: 'UZS',
    name: 'Uzbekistan Sum',
  },
  {
    id: 'VUV',
    name: 'Vatu',
  },
  {
    id: 'KRW',
    name: 'Won',
  },
  {
    id: 'YER',
    name: 'Yemeni Rial',
  },
  {
    id: 'ZMK',
    name: 'Zambian Kwacha',
  },
  {
    id: 'ZWL',
    name: 'Zimbabwe Dollar',
  },
]
