import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  SelectField,
  RichTextField,
  BooleanField,
} from 'react-admin'

import { mailtoOptions } from './MailTemplateInput'
import Title from './MailTemplateTitle'

const MailTemplateShow = (props) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="subject" />
      <SelectField source="to" choices={mailtoOptions} />
      <SelectField source="cc" choices={mailtoOptions} />
      <SelectField source="bcc" choices={mailtoOptions} />
      <BooleanField source="signed" />
      <RichTextField source="body" />
    </SimpleShowLayout>
  </Show>
)

export default MailTemplateShow
