import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, fetchStart, fetchEnd } from 'react-admin'

import { apiUrl, airbnbDashboardUrl } from '../config'
import { httpClient } from '../dataProvider'
import { useAuth0 } from '../auth0-spa'

const LoginAsUserButton = ({ record }) => {
  const dispatch = useDispatch()
  const { token } = useAuth0()

  const handleClick = async () => {
    dispatch(fetchStart()) // start the global loading indicator
    const result = await httpClient(
      token,
      `${apiUrl}/claim/${record.claimId}/createUserImpersonationToken`,
      {
        method: 'POST',
      },
    )
    const userToken = result.json.result.token
    window.open(`${airbnbDashboardUrl}?adminToken=${userToken}`)

    dispatch(fetchEnd()) // stop the global loading indicator
  }

  return <Button label="Log in as user" onClick={handleClick} />
}

export default LoginAsUserButton
