/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SimpleList,
  TextField,
  TextInput,
  SearchInput,
  ReferenceField,
} from 'react-admin'

// import PropertyEditEmbedded from './PropertyEditEmbedded'

const PropertyFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="fullName" />
  </Filter>
)

const PropertyBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
)

const PropertyList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<PropertyFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<PropertyBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList primaryText={(record) => record.fullName} />
    ) : (
      <Datagrid
        rowClick={'show'}
        /*expand={<PropertyEditEmbedded />}*/
        optimized
      >
        <TextField source="address" />
        <TextField source="owner" />
        <ReferenceField
          label="Agent"
          source="agentId"
          reference="agent"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    )}
  </List>
)

export default PropertyList
