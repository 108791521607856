import React, { useState, useEffect, useContext } from 'react'
import auth0 from './auth0'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [token, setToken] = useState()
  console.log('token', token)
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      console.log('1')
      const auth0FromHook = await auth0()
      setAuth0(auth0FromHook)
      console.log('3')

      if (window.location.search.includes('code=')) {
      console.log('4 -exit')
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      console.log('5')
      const isAuthenticated = await auth0FromHook.isAuthenticated()

      console.log('6')
      setIsAuthenticated(isAuthenticated)

      console.log('7', isAuthenticated)
      if (isAuthenticated) {
        const [token, user] = await Promise.all([
          auth0FromHook.getTokenSilently(),
          auth0FromHook.getUser(),
        ])
        setUser({ ...user, auth0Id: user.sub.split('|')[1] })
        console.log('setting token 1', token)
        setToken(token)
      }

      setLoading(false)
    }
    initAuth0()

    const storedToken = localStorage.getItem('token')
    setToken(storedToken)


    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    console.log('popup')
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const token = await auth0Client.getTokenSilently()
    setToken(token)
    console.log('setting token 2', token)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    console.log('redirect')
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const token = await auth0Client.getTokenSilently()
    setLoading(false)
    setIsAuthenticated(true)
    console.log('setting token 3', token)
    setToken(token)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        token,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => {
          localStorage.removeItem('token')
          return auth0Client.logout(...p)
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
