import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'

import Title from './RoleTitle'

const RoleShow = (props) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="allowActors" />
      <DateField source="createdAt" />
      <TextField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

export default RoleShow
