import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin'

import Title from './ActorTitle'

const ActorCreate = (props) => (
  <Create title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={required()} />
      <TextInput source="auth0Id" />
      <ReferenceInput
        source="roleId"
        reference="role"
        filter={{ allowActors: true }}
      >
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default ActorCreate
