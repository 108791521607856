import React from 'react'
import {
  Show,
  TextField,
  ReferenceField,
  FunctionField,
  TabbedShowLayout,
  Tab,
  RichTextField,
} from 'react-admin'

import Title from './CourtTitle'

const CourtShow = (props) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="location" />
        <TextField source="phone" />

        <FunctionField
          source="url"
          render={({ url }) => (
            <a href={`${url}`} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          )}
        />
        <ReferenceField source="visitingAddressId" reference="property">
          <TextField source="address" />
        </ReferenceField>
        <ReferenceField source="postalAddressId" reference="property">
          <TextField source="address" />
        </ReferenceField>
      </Tab>
      <Tab label="Notes">
        <RichTextField source="notes" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default CourtShow
