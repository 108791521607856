import React, { cloneElement, Children } from 'react'
import PropTypes from 'prop-types'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  ReferenceArrayField,
  DateField,
  BooleanField,
  TopToolbar,
  EditButton,
  SingleFieldList,
  ChipField,
  Datagrid,
  NumberField,
  Link,
  linkToRecord,
  useListContext,
} from 'react-admin'

import SendMailButton from './SendMailButton'
import SendDefaultNoticeToAirbnbButton from './SendDefaultNoticeToAirbnbButton'
import SendGenericNoticeToAirbnbButton from './SendGenericNoticeToAirbnbButton'
import LoginAsUserButton from './LoginAsUserButton'
// import AirbnbClaimTitle from './AirbnbClaimTitle'
import ActionsTab from '../shared/ActionsTab'

import Button from '@material-ui/core/Button'

// import { airbnbDashboardUrl } from '../config'

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <SendMailButton record={data} />
    <SendDefaultNoticeToAirbnbButton record={data} />
    <SendGenericNoticeToAirbnbButton record={data} />
    <LoginAsUserButton record={data} />
  </TopToolbar>
)

/*
const DashboardLink = ({ record, ...props }) => (
  <ReferenceField
    source="claimId"
    reference="claim"
    link={false}
    record={record}
    label="Dashboard"
    {...props}
  >
    <FunctionField
      addLabel={false}
      render={(reference) => (
        <Button
          variant="contained"
          target="_blank"
          href={`${airbnbDashboardUrl}?key=${reference.key}&loginKey=${record.loginKey}`}
        >
          Open Dashboard
        </Button>
      )}
    />
  </ReferenceField>
)
*/

// link to the correct claim, without this helper function we would link
// to the claimId instead of the airbnbclaimId
const LinkToClaim = (props) => {
  const { data, basePath } = useListContext(props)
  const claim = Object.values(data).find((c) => c.contactId === props.record.id)
  return (
    <Link to={linkToRecord(basePath, claim.airbnbClaimId, 'show')}>
      {cloneElement(Children.only(props.children), props)}
    </Link>
  )
}

const AirbnbClaimShow = ({ ...props }) => (
  <Show actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <ReferenceField
          label="Key"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="key" />
        </ReferenceField>
        <TextField source="loginKey" />
        {/*<DashboardLink label="Dashboard" />*/}

        <ReferenceField
          label="Contact"
          source="contactId"
          reference="contact"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>

        <NumberField source="totalAmount" />
        <TextField source="feePercentage" />
        <TextField source="interest" />
        <TextField source="totalCosts" />

        <ReferenceField
          label="allowedProxy"
          source="claimId"
          reference="claim"
          link={false}
        >
          <DateField source="allowedProxy" />
        </ReferenceField>
        <ReferenceField
          label="Boek"
          source="contactId"
          reference="contact"
          link={false}
        >
          <TextField source="book" />
        </ReferenceField>
        <BooleanField source="paid" />

        <ReferenceField
          label="Promo code"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="promoCode" />
        </ReferenceField>

        <ReferenceField
          label="Referred by"
          source="claimId"
          reference="claim"
          link={false}
        >
          <ReferenceField source="referredById" reference="claim" link={false}>
            <TextField source="key" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          label="Referrals"
          source="claimId"
          reference="claim"
          link={false}
        >
          <ReferenceManyField target="referredById" reference="claim">
            <SingleFieldList linkType={false}>
              <ReferenceField
                label="Client"
                source="contactId"
                reference="contact"
                link={false}
              >
                <LinkToClaim>
                  <ChipField source="fullName" />
                </LinkToClaim>
              </ReferenceField>
            </SingleFieldList>
          </ReferenceManyField>
        </ReferenceField>

        <NumberField source="unparsedClaims" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="Notes">
        <ReferenceField
          label="notes"
          source="claimId"
          reference="claim"
          link={false}
        >
          <RichTextField source="notes" />
        </ReferenceField>
      </Tab>
      <Tab label="Receipts" path="receipts">
        <ReferenceArrayField source="receiptsIds" reference="airbnbReceipt">
          <Datagrid>
            <FunctionField
              source="fileLink"
              addLabel={false}
              render={(record) => (
                <Button
                  variant="contained"
                  target="_blank"
                  href={record.fileLink}
                >
                  Open File
                </Button>
              )}
            />
            <TextField source="airbnbCode" />
            <TextField source="amount" />
            <DateField source="dateOfPayment" />
            <TextField source="status" />
            <TextField source="rejectionReason" />
            <NumberField source="lastParserVersionRun" />
            <BooleanField source="succesfullyParsed" />
            <BooleanField source="succesfullyConverted" />
          </Datagrid>
        </ReferenceArrayField>
        <FunctionField
          source="googleDriveFolderLink"
          addLabel={false}
          style={{ paddingBottom: 10, display: 'block' }}
          render={(record) => (
            <Button
              variant="contained"
              target="_blank"
              href={record.googleDriveFolderLink}
            >
              Open Folder
            </Button>
          )}
        />
      </Tab>
      <ActionsTab />
    </TabbedShowLayout>
  </Show>
)

AirbnbClaimShow.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default AirbnbClaimShow
