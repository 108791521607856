import React from 'react'
import PropTypes from 'prop-types'
import { Edit, TextInput, SimpleForm, required } from 'react-admin'

import Title from './ClaimStateTitle'

const ClaimStateEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
)

ClaimStateEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default ClaimStateEdit
