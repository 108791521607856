import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  useDataProvider,
  useNotify,
  LinearProgress,
  fetchStart,
  fetchEnd,
  Error,
} from 'react-admin'
import { fetchUtils } from 'ra-core'

import { apiUrl } from '../config'
import GenerateFileDialog from './GenerateFileDialog'
import { useAuth0 } from '../auth0-spa'

const GenerateFileButton = ({ record }) => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [files, setFiles] = useState()

  useEffect(() => {
    const getFiles = () =>
      dataProvider
        .getList(`../claim/${record.claimId}/template`)
        .then((result) => {
          if (!result) {
            // request was canceled
            return
          }
          setFiles(result.data)
          setLoading(false)
        })
        .catch((error) => {
          setError(error)
          setLoading(false)
        })

    if (record) {
      getFiles()
    }
  }, [dataProvider, record])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const createFileInDrive = async (claimId, templateId, token) => {
    const url = `${apiUrl}/claim/${claimId}/createFileInDrive`
    const options = {
      method: 'POST',
      body: JSON.stringify({ templateId }),
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      }),
    }
    let result
    try {
      result = await fetchUtils.fetchJson(url, options).then((r) => r.json)
    } catch (err) {
      notify(err.message, 'warning')
      return false
    }
    return result
  }

  const handleClose = async (token, value, download) => {
    setOpen(false)
    dispatch(fetchStart()) // start the global loading indicator
    if (value) {
      const result = await createFileInDrive(record.claimId, value.id, token)

      if (!result) {
        return
      }

      if (!download) {
        notify('Files generated and saved in google drive')
        dispatch(fetchEnd()) // stop the global loading indicator
        return
      }

      /*
      // download all the created files as pdf
      const response = await downloadFileFromDrive(result.driveFileId, token)
      const name = parseContentDisposition(
        response.headers.get('content-disposition'),
      ).parameters.filename
      const blob = await response.blob()
      */
      // TODO: create to download
      // multiDownload(toDownload)
      dispatch(fetchEnd()) // stop the global loading indicator
    }
  }

  const { token } = useAuth0()

  if (loading || !record) {
    return <LinearProgress />
  }
  if (error) {
    return <Error error={error} />
  }

  return (
    <>
      {!loading && (
        <GenerateFileDialog
          open={open}
          onClose={(value, download) => handleClose(token, value, download)}
          files={files}
        />
      )}
      <Button label="Generate File" onClick={handleClickOpen} />
    </>
  )
}

export default GenerateFileButton
