import React, { useState, useEffect } from 'react'
import keyBy from 'lodash/keyBy'
import {
  useDataProvider,
  Datagrid,
  TextField,
  Loading,
  Error,
} from 'react-admin'

const GoogleDriveList = ({ record }) => {
  const dataProvider = useDataProvider()
  const [fileList, setFileList] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  useEffect(() => {
    dataProvider
      .getList(`../agencyFeeClaim/${record.id}/googleDriveFileList`)
      .then(({ data }) => {
        setFileList(data)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [dataProvider, record.id])

  if (loading) return <Loading />
  if (error) return <Error />
  if (!fileList) return null

  const list = fileList.driveFiles

  return (
    <Datagrid
      data={keyBy(list, 'id')}
      ids={list.map(({ id }) => id)}
      currentSort={{ field: 'id', order: 'ASC' }}
      rowClick={(id, basePath, record) => {
        const url = `https://docs.google.com/document/d/${record.id}/edit`
        window.open(url, '_blank')
        return false
      }}
      selectedIds={[]}
    >
      <TextField source="name" sortable={false} />
      <TextField source="mimeType" sortable={false} />
    </Datagrid>
  )
}

export default GoogleDriveList
