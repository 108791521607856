/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SimpleList,
  TextField,
  SearchInput,
} from 'react-admin'

// import CourtEditEmbedded from './CourtEditEmbedded'

const CourtFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

const CourtBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
)

const CourtList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<CourtFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<CourtBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList primaryText={(record) => record.name} />
    ) : (
      <Datagrid rowClick={'show'} optimized>
        <TextField source="name" />
        <TextField source="location" />
      </Datagrid>
    )}
  </List>
)

export default CourtList
