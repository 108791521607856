import React from 'react'
import { ReferenceFieldController, ReferenceField } from 'react-admin'

const NestedReferenceField = ({
  label,
  source,
  reference,
  nestedSource,
  nestedReference,
  children,
  link,
  ...rest
}) => (
  <ReferenceFieldController
    label={label}
    reference={reference}
    source={source}
    link={false}
    {...rest}
  >
    {({ referenceRecord }) => (
      <ReferenceField
        record={referenceRecord || {}}
        basePath={`/${nestedReference}`}
        source={nestedSource}
        reference={nestedReference}
        label={label}
        link={link}
      >
        {children}
      </ReferenceField>
    )}
  </ReferenceFieldController>
)

export default NestedReferenceField
