import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DateField,
  BooleanField,
} from 'react-admin'

import Title from './ActionTemplateTitle'

const ActionTemplateShow = (props) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ReferenceField source="claimStateId" reference="claimState" link="show">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="isInitialState" />
      <BooleanField source="isFinalState" />
      <TextField source="extraFields" />
      <TextField source="description" />
      <BooleanField source="assignToPreviousActorByDefault" />
      <TextField source="defaultNextActionDelay" />
      <ReferenceArrayField
        source="possibleNextActionsIds"
        reference="actionTemplate"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="reminderText" />
      <ReferenceArrayField
        source="canBeDoneByRolesIds"
        reference="role"
        label="Can Be Done By"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="claimType" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

export default ActionTemplateShow
