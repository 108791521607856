import React, { useState, useEffect } from 'react'
import {
  Button,
  useDataProvider,
  LinearProgress,
  Error,
  useNotify,
} from 'react-admin'
import { httpClient } from '../dataProvider'

import { apiUrl } from '../config'
import { useAuth0 } from '../auth0-spa'
import { useSelector } from 'react-redux'

import CreateMailDialog from './CreateMailDialog'

import getFullResources from '../shared/getFullResources'

const CreateMailButton = ({ record, selectedIds }) => {
  const agencyFeeClaims = useSelector(getFullResources('agencyFeeClaim'))
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [templates, setTemplates] = useState()

  useEffect(() => {
    const getTemplates = () =>
      dataProvider
        .getList('mailTemplate')
        .then((result) => {
          if (!result) {
            // request was canceled
            return
          }
          setTemplates(result.data)
          setLoading(false)
        })
        .catch((error) => {
          setError(error)
          setLoading(false)
        })

    getTemplates()
  }, [dataProvider])

  // generalise for multiple records
  if (record && !selectedIds) {
    selectedIds = [record.id]
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const { token } = useAuth0()

  if (loading || !selectedIds) {
    return <LinearProgress />
  }
  if (error) {
    return <Error error={error} />
  }

  const claimIds = selectedIds.map(
    (id) => agencyFeeClaims && agencyFeeClaims[id]?.claimId,
  )

  const handleClose = async (value) => {
    setOpen(false)
    if (!value) {
      // dialog was closed
      return
    }
    const requests = claimIds.map((claimId) =>
      httpClient(
        token,
        `${apiUrl}/claim/${claimId}/createEmailUrl?mailTemplateId=${value.id}`,
      ),
    )
    let result
    try {
      result = await Promise.all(requests)
    } catch (err) {
      notify(err.message, 'warning')
      return
    }
    result.forEach((r) => {
      const link = r.json.result
      window.open(link)
    })
  }

  return (
    <>
      {!loading && (
        <CreateMailDialog
          open={open}
          onClose={handleClose}
          templates={templates}
        />
      )}
      <Button label="Create Email" onClick={handleClickOpen} />
    </>
  )
}

export default CreateMailButton
