import React, { useCallback, Children } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import {
  FormInput,
  Toolbar,
  CardContentInner,
  useInitializeFormWithRecord,
  useNotify,
  useCreate,
  useVersion,
  useRefresh,
  useCreateController,
  CreateContextProvider,
  getFormInitialValues,
  sanitizeEmptyValues,
  CRUD_CREATE,
} from 'react-admin'

const CustomForm = ({
  initialValues,
  debug,
  decorators,
  defaultValue,
  form,
  initialValuesEqual,
  keepDirtyOnReinitialize = true,
  mutators = arrayMutators,
  record,
  render,
  subscription = defaultSubscription,
  validate,
  validateOnBlur,

  resource,
  id,
  successMessage,
  basePath,
  ...props
}) => {
  const notify = useNotify()
  // const recordToUse = getRecord(location, record)
  const version = useVersion()
  const refresh = useRefresh()

  const [create, { loading: saving }] = useCreate(resource)

  const save = useCallback(
    (data, { onSuccess, onFailure } = {}) =>
      create(
        { payload: { data } },
        {
          action: CRUD_CREATE,
          onSuccess: onSuccess
            ? onSuccess
            : ({ data }) => {
                notify(successMessage || 'ra.notification.created', 'info', {
                  smart_count: 1,
                })
                refresh()
              },
          onFailure: onFailure
            ? onFailure
            : (error) => {
                notify(
                  typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error',
                  'warning',
                )
              },
        },
      ),
    [create, refresh, notify, successMessage],
  )

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    record,
  )

  const submit = (values) => {
    const finalValues = sanitizeEmptyValues(finalInitialValues, values)

    save(finalValues)
  }

  return (
    <Form
      key={version} // support for refresh button
      debug={debug}
      decorators={decorators}
      form={form}
      initialValues={finalInitialValues}
      initialValuesEqual={initialValuesEqual}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      mutators={mutators} // necessary for ArrayInput
      onSubmit={submit}
      subscription={subscription} // don't redraw entire form each time one field changes
      validate={validate}
      validateOnBlur={validateOnBlur}
    >
      {(formProps) => (
        <FormView
          {...props}
          {...formProps}
          record={record}
          saving={formProps.submitting || saving}
          render={render}
        />
      )}
    </Form>
  )
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

const FormView = ({ render, ...props }) => {
  // if record changes (after a getOne success or a refresh), the form must be updated
  useInitializeFormWithRecord(props.record)

  return render({
    ...props,
  })
}

const SimpleForm = ({
  setRecordTo,
  setResourceTo,
  setRecordToProp,
  resource,
  record,
  ...props
}) => {
  props.record = setRecordTo ? setRecordTo : record
  props.resource = setResourceTo ? setResourceTo : record
  if (setRecordToProp) {
    props.record = { [setRecordToProp]: props.record[setRecordToProp] }
  }
  const controllerProps = useCreateController(props)
  return (
    <CreateContextProvider value={controllerProps}>
      <CustomForm
        {...props}
        render={(formProps) => <SimpleFormView {...formProps} />}
      />
    </CreateContextProvider>
  )
}

SimpleForm.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number,
}

const SimpleFormView = ({
  basePath,
  children,
  className,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  margin,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  toolbar,
  undoable,
  variant,
  ...rest
}) => {
  return (
    <form
      className={classnames('simple-form', className)}
      {...sanitizeRestProps(rest)}
    >
      <CardContentInner>
        {Children.map(
          children,
          (input) =>
            input && (
              <FormInput
                basePath={basePath}
                input={input}
                record={record}
                resource={resource}
                variant={input.props.variant || variant}
                margin={input.props.margin || margin}
              />
            ),
        )}
      </CardContentInner>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
    </form>
  )
}

SimpleFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
}

SimpleFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar />,
}

const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  pristine,
  pure,
  reset,
  resetSection,
  save,
  submit,
  submitError,
  submitErrors,
  submitAsSideEffect,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  _reduxForm,
  ...props
}) => props

export default SimpleForm
