import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  ReferenceManyField,
  Datagrid,
} from 'react-admin'

import Title from './PropertyTitle'

const PropertyShow = (props) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="address" />
      <TextField source="zipcode" />
      <TextField source="owner" />
      <ReferenceField source="agentId" reference="agent" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceManyField reference="agencyFeeClaim" target="propertyId">
        <Datagrid rowClick="show">
          <ReferenceField
            label="Key"
            source="claimId"
            reference="claim"
            link={false}
          >
            <TextField source="key" />
          </ReferenceField>
          <TextField source="amount" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export default PropertyShow
