import PeopleIcon from '@material-ui/icons/People'
import AgentList from './AgentList'
import AgentShow from './AgentShow'
import AgentEdit from './AgentEdit'

export default {
  list: AgentList,
  show: AgentShow,
  edit: AgentEdit,
  icon: PeopleIcon,
}
