import React, { useState } from 'react'
import {
  TextInput,
  DateInput,
  FunctionField,
  ReferenceField,
  SelectInput,
  DateField,
  NumberInput,
  Loading,
  required,
} from 'react-admin'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Actions from './Actions'

import { currencyList } from './currencyList'

const Input = (props) => {
  const [loading, setLoading] = useState(false)
  if (!props.record || !props.queueEntry) {
    return <Loading />
  }

  return (
    <>
      <Grid container>
        <Actions
          record={props.record}
          queueEntry={props.queueEntry}
          setVersion={props.setVersion}
          setLoading={setLoading}
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Box py="20px" display="flex" flexDirection="column">
            <FunctionField
              record={props.record}
              render={(record) => `Airbnb Receipt #${record.id}`}
            />
            <ReferenceField
              label="Client"
              source="airbnbClaimId"
              reference="airbnbClaim"
              link={false}
              basePath="contact"
              record={props.record}
            >
              <DateField source="createdAt" />
            </ReferenceField>
            <ReferenceField
              label="Client"
              source="airbnbClaimId"
              reference="airbnbClaim"
              link={false}
              basePath="contact"
              record={props.record}
            >
              <ReferenceField
                source="contactId"
                reference="contact"
                link="show"
              >
                <FunctionField
                  render={(record) => `${record.fullName} (${record.email})`}
                />
              </ReferenceField>
            </ReferenceField>

            <SelectInput
              source="currency"
              choices={currencyList}
              validate={[required()]}
            />

            <TextInput source="airbnbCode" validate={[required()]} />
            <NumberInput source="amount" validate={[required()]} />
            <DateInput source="dateOfPayment" validate={[required()]} />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <iframe
            title="Preview"
            src={props.record.fileLink}
            style={{ width: '100%', minHeight: '700px' }}
          />
        </Grid>
      </Grid>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  )
}

export default Input
