import createAuth0Client from '@auth0/auth0-spa-js'
import { auth0Domain, clientId, redirectUri, audience } from './config'

let auth0
const auth0Provider = async () => {
  if (!auth0) {
    auth0 = await createAuth0Client({
      domain: auth0Domain,
      client_id: clientId,
      redirect_uri: redirectUri,
      audience,
      scope:
        'claim:read claim:create claim:update claim:delete acquisition:read acquisition:create acquisition:update acquisition:delete contact:read contact:create contact:update contact:delete mailjet:create mailjet:update mailjet:delete action:read action:create action:update action:delete mailjet:read agent:read agent:create agent:update agent:delete document:read document:create document:update document:delete property:read property:create property:update property:delete drive:read drive:create drive:update drive:delete crookCatcher:read crookCatcherLetter:read crookCatcherLetter:create crookCatcherLetter:update crookCatcherLetter:delete actionTemplate:read actionTemplate:create actionTemplate:update actionTemplate:delete actor:read actor:create actor:update actor:delete role:read role:create role:update role:delete airbnbClaim:read airbnbClaim:create airbnbClaim:update airbnbClaim:delete claimState:read claimState:create claimState:update claimState:delete airbnbReceipt:read airbnbReceipt:create airbnbReceipt:update airbnbReceipt:delete airbnbReceiptQueueEntry:read airbnbReceiptQueueEntry:create airbnbReceiptQueueEntry:update airbnbReceiptQueueEntry:delete agencyFeeClaim:read agencyFeeClaim:create agencyFeeClaim:update agencyFeeClaim:delete agencyFeeClaimFile:read agencyFeeClaimFile:create agencyFeeClaimFile:update agencyFeeClaimFile:delete uploadedFile:read uploadedFile:create uploadedFile:update uploadedFile:delete mailTemplate:read mailTemplate:create mailTemplate:update mailTemplate:delete court:read court:create court:update court:delete',
    })
  }
  return auth0
}

export default auth0Provider
