import React from 'react'
import {
  TextInput,
  DateTimeInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
  useQueryWithStore,
  useGetOne,
  Loading,
  Error,
  TextField,
} from 'react-admin'

import Grid from '@material-ui/core/Grid'

import { useSelector } from 'react-redux'

import { parse, toSeconds } from 'iso8601-duration'

const currentDateTime = () => {
  let now = new Date()
  const offset = now.getTimezoneOffset()
  now = new Date(now.getTime() - offset * 60 * 1000)
  return now.toISOString().split('.')[0] // ignore milliseconds
}

const getDateAfterDuration = (doneAt, duration) => {
  const delay = toSeconds(parse(duration)) * 1000
  let date = new Date(doneAt)
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - offset * 60 * 1000 + delay)
  return date.toISOString().split('.')[0] // ignore milliseconds
}

const getRoles = () => (state) => {
  return state?.admin?.resources?.role?.data
}

const FilteredSelectInput = ({ filter, choices, ...props }) => (
  <SelectInput optionText="name" choices={choices.filter(filter)} {...props} />
)

const ActionInput = ({ ...props }) => {
  const claimRequest = useGetOne('claim', props.record.claimId)
  const roles = useSelector(getRoles())
  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'actionTemplate',
    payload: {
      pagination: {
        page: 1,
        perPage: 99999,
      },
    },
  })

  if (!loaded || !claimRequest.loaded) {
    return <Loading />
  }
  if (error || claimRequest.error) {
    return <Error />
  }

  const claim = claimRequest.data

  // index actionTemplates on id
  const actionTemplates = data.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})

  const lastActionTemplate = actionTemplates[claim.lastActionTemplateId]
  return (
    <Grid container>
      <Grid item xs={12}>
        <DateTimeInput source="doneAt" defaultValue={currentDateTime()} />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput source="_role" reference="role" label="Role" {...props}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <FormDataConsumer {...props}>
          {({ formData, ...rest }) => {
            const roleId = formData._role

            // if actionTemplates[claim.lastActionTemplateId] does not exist we
            // assume that the actionTemplate list is still loading
            if (!roleId || !actionTemplates[claim.lastActionTemplateId]) {
              return null
            }

            // check if there is an possible action that can be done by the
            // currently selected role
            if (
              !lastActionTemplate.possibleNextActionsIds.some((id) =>
                actionTemplates[id].canBeDoneByRolesIds.includes(roleId),
              )
            ) {
              return null
            }

            const checkIfStillValid = () => (value) => {
              return actionTemplates[value].canBeDoneByRolesIds.includes(roleId)
                ? undefined
                : 'Required'
            }

            return (
              <ReferenceInput
                source="actionTemplateId"
                reference="actionTemplate"
                label="Template"
                perPage={99999}
                {...props}
              >
                <FilteredSelectInput
                  filter={(choice) =>
                    lastActionTemplate.possibleNextActionsIds.includes(
                      choice.id,
                    ) &&
                    actionTemplates[choice.id].canBeDoneByRolesIds.includes(
                      roleId,
                    )
                  }
                  optionText="name"
                  validate={[required(), checkIfStillValid()]}
                />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>
      </Grid>
      <FormDataConsumer {...props}>
        {({
          formData, // The whole form data
          ...rest
        }) => {
          const roleId = formData._role
          const actionTemplateId = formData.actionTemplateId

          if (!actionTemplateId || !roleId || !roles[roleId]) {
            return null
          }

          const role = roles[roleId]

          const actionTemplate = actionTemplates[actionTemplateId]
          if (!actionTemplate.possibleNextActionsIds) {
            return 'Action Template is Incomplete'
          }

          return (
            <>
              {role.allowActors && (
                <Grid item xs={12}>
                  <ReferenceInput
                    source={'doneById'}
                    reference="actor"
                    {...rest}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextInput multiline source="notes" {...rest} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Volgende actie"
                  source="reminderText"
                  record={actionTemplate}
                />
              </Grid>
              {actionTemplate.defaultNextActionDelay && (
                <Grid item xs={12}>
                  <DateInput
                    source="nextActionAt"
                    defaultValue={getDateAfterDuration(
                      new Date(formData.doneAt),
                      actionTemplate.defaultNextActionDelay,
                    )}
                    {...rest}
                  />
                </Grid>
              )}
            </>
          )
        }}
      </FormDataConsumer>
    </Grid>
  )
}

export default ActionInput
