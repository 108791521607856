import PeopleIcon from '@material-ui/icons/People'
import RoleList from './RoleList'
import RoleShow from './RoleShow'
import RoleEdit from './RoleEdit'
import RoleCreate from './RoleCreate'

export default {
  list: RoleList,
  show: RoleShow,
  edit: RoleEdit,
  create: RoleCreate,
  icon: PeopleIcon,
}
