import React from 'react'
import PropTypes from 'prop-types'
import { Edit } from 'react-admin'

import Title from './MailTemplateTitle'
import MailTemplateInput from './MailTemplateInput'

const MailTemplateEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <MailTemplateInput />
  </Edit>
)

MailTemplateEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default MailTemplateEdit
