import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

const RejectionDialog = (props) => {
  const { onClose, open, reasons } = props

  const handleClose = () => {
    onClose(false)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Reject with reason:</DialogTitle>
      <List>
        {reasons.map((reason) => {
          return (
            <ListItem
              button
              onClick={() => handleListItemClick(reason)}
              key={reason}
            >
              <ListItemText primary={reason} />
            </ListItem>
          )
        })}
      </List>
    </Dialog>
  )
}

export default RejectionDialog
