import React from 'react'
import { Create } from 'react-admin'

import Title from './ActionTemplateTitle'
import ActionTemplateInput from './ActionTemplateInput'

const ActionTemplateShow = (props) => (
  <Create title={<Title />} {...props}>
    <ActionTemplateInput />
  </Create>
)

export default ActionTemplateShow
