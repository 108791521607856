import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'

import Title from './ClaimStateTitle'

const ClaimStateCreate = (props) => (
  <Create title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
)

export default ClaimStateCreate
