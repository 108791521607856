import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Confirm,
  fetchStart,
  fetchEnd,
  useNotify,
  useRefresh,
} from 'react-admin'

import { apiUrl } from '../config'
import { httpClient } from '../dataProvider'
import { useAuth0 } from '../auth0-spa'

const SendDefaultNoticeMailButton = ({ record, selectedIds }) => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const refresh = useRefresh()

  // generalise for multiple records
  if (record && !selectedIds) {
    selectedIds = [record.id]
  }

  const handleClickOpen = () => {
    setConfirmOpen(true)
  }

  const { token } = useAuth0()

  const handleConfirm = async () => {
    setConfirmOpen(false)
    dispatch(fetchStart()) // start the global loading indicator
    const requests = selectedIds.map((id) =>
      httpClient(token, `${apiUrl}/airbnbClaim/${id}/resendDefaultNotice`, {
        method: 'POST',
      }),
    )
    await Promise.all(requests)
    notify('Default notice to airbnb sent.')
    // refresh the view so actions get updated
    refresh()
    dispatch(fetchEnd()) // stop the global loading indicator
  }

  return (
    <>
      <Confirm
        isOpen={confirmOpen}
        title="Send email"
        content="Are you sure you want to (re)send the default notice to airbnb?"
        onConfirm={handleConfirm}
        onClose={() => setConfirmOpen(false)}
      />
      <Button label="Send default notice to Airbnb" onClick={handleClickOpen} />
    </>
  )
}

export default SendDefaultNoticeMailButton
