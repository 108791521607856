import PeopleIcon from '@material-ui/icons/People'
import ContactList from './ContactList'
import ContactShow from './ContactShow'
import ContactEdit from './ContactEdit'

export default {
  list: ContactList,
  show: ContactShow,
  edit: ContactEdit,
  icon: PeopleIcon,
}
