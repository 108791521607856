import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  EmailField,
  DateField,
  ReferenceManyField,
  ReferenceField,
  FunctionField,
  Datagrid,
} from 'react-admin'

import Title from './AgentTitle'
import NestedReferenceField from '../shared/NestedReferenceField'

import Button from '@material-ui/core/Button'

const AgentShow = (props) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="street" />
        <TextField source="houseNumber" />
        <TextField source="city" />
        <TextField source="zipcode" />
        <EmailField source="email" />
        <TextField source="website" />
        <TextField source="entity" />
        <TextField source="kvkNumber" />
        <TextField source="feeType" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ReferenceManyField
          reference="property"
          target="agentId"
          label="Properties"
        >
          <Datagrid rowClick="show">
            <TextField source="address" />
            <TextField source="zipcode" />
            <TextField source="owner" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          reference="agencyFeeClaim"
          target="agentId"
          label="Claims"
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label="Key"
              source="claimId"
              reference="claim"
              link={false}
            >
              <TextField source="key" />
            </ReferenceField>
            <NestedReferenceField
              label="Client"
              source="claimId"
              nestedSource="contactId"
              reference="claim"
              nestedReference="contact"
              link="show"
            >
              <TextField source="fullName" />
            </NestedReferenceField>
            <TextField source="totalCosts" />
          </Datagrid>
        </ReferenceManyField>
        <FunctionField
          label="KVK link"
          render={(record) =>
            record.kvkNumber && (
              <Button
                variant="contained"
                target="_blank"
                href={`https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=${record.kvkNumber}`}
              >
                Open KVK
              </Button>
            )
          }
        />
      </Tab>
      <Tab label="Notes">
        <RichTextField source="notes" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default AgentShow
