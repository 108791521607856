/* eslint react/jsx-key: off */
import React, { useState } from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  Link,
  TextField,
  // SearchInput,
  FunctionField,
  ReferenceField,
  getResourceLinkPath,
  ReferenceInput,
  Loading,
  SelectInput,
  useGetList,
} from 'react-admin'
import humanizeDuration from 'humanize-duration'

import { useAuth0 } from '../auth0-spa'
import { Chip } from '@material-ui/core'

import NestedReferenceField from '../shared/NestedReferenceField'
import AssignButton from './AssignButton'

import { apiUrl } from '../config'

const ActionFilter = ({ ...props }) => (
  <Filter {...props}>
    <Chip
      source="nextActionAt:isNotNull"
      label="Has due date"
      defaultValue={true}
    />
    <ReferenceInput
      label="Assigned to"
      source="nextActionAssigneeId"
      reference="actor"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="State" source="claimStateId" reference="claimState">
      <SelectInput optionText="name" />
    </ReferenceInput>
    {/*<SearchInput source="q" alwaysOn />*/}
  </Filter>
)

const ActionBulkActionButtons = (props) => [
  <BulkDeleteWithConfirmButton {...props} />,
  <AssignButton {...props} />,
]

let now = new Date()
now.setHours(0, 0, 0, 0)
const offset = now.getTimezoneOffset()
now = new Date(now.getTime() - offset * 60 * 1000)

const getDueIn = (record) => {
  if (!record) {
    return null
  }
  if (!record.nextActionAt) {
    return 'nooit'
  }
  const nextActionAt = new Date(record.nextActionAt)
  const dueIn = nextActionAt - now

  if (dueIn === 0) {
    return 'vandaag'
  }

  const duration = humanizeDuration(dueIn, {
    language: 'nl',
    round: true,
    units: ['d'],
  })
  return `${duration}${dueIn < 0 ? ' geleden' : ''}`
}

const typeList = { VSPA: 'agencyFeeClaim', ABNB: 'airbnbClaim' }
const ClaimKey = (props) => {
  const type = typeList[props.record.type]
  const id = props.record[type + 'Id']
  const link = getResourceLinkPath({
    ...props,
    resource: 'action',
    reference: type,
    record: { id },
    link: 'show',
    source: 'id',
  })
  return (
    <Link to={link}>
      <TextField source="key" {...props} />
    </Link>
  )
}

const ActionList = ({ permissions, ...props }) => {
  const [actor, setActor] = useState()
  const {
    user: { auth0Id },
  } = useAuth0()

  // TODO: make the db filter this instead of locally
  const { data, loading, total } = useGetList('actor')

  if (!actor && (loading || !data || !total)) {
    return <Loading />
  }
  const currentActor = Object.values(data).find(
    (actor) => actor.auth0Id === auth0Id,
  )
  if (!actor && currentActor) {
    setActor(currentActor)
  }

  if (!actor) {
    return <Loading />
  }

  return (
    <List
      {...props}
      filters={<ActionFilter />}
      filterDefaultValues={{
        'nextActionAt:isNotNull': true,
        nextActionAssigneeId: actor.id,
      }}
      filter={{
        _getAt: `${apiUrl}/todoList`,
      }}
      sort={{ field: 'nextActionAt', order: 'ASC' }}
      bulkActionButtons={<ActionBulkActionButtons />}
    >
      <Datagrid>
        <ReferenceField
          source="claimId"
          reference="claim"
          link={false}
          {...props}
        >
          <ClaimKey />
        </ReferenceField>
        <NestedReferenceField
          label="Client"
          source="claimId"
          nestedSource="contactId"
          reference="claim"
          nestedReference="contact"
          link="show"
        >
          <TextField source="fullName" />
        </NestedReferenceField>
        <TextField source="reminderText" />
        <ReferenceField
          source="nextActionAssigneeId"
          reference="actor"
          link={false}
          label="Assigned to"
        >
          <TextField source="name" />
        </ReferenceField>{' '}
        <FunctionField label="Due in" render={getDueIn} />
        <ReferenceField
          source="claimStateId"
          reference="claimState"
          link={false}
          label="State"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

export default ActionList
