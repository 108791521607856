import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  ReferenceField,
  FunctionField,
  DateField,
} from 'react-admin'

import Title from './ContactTitle'

const ContactShow = (props) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="fullName" />
        <FunctionField
          source="email"
          render={({ email }) => (
            <a href={`mailto-frontapp:${email}`}>{email}</a>
          )}
        />

        <TextField source="phone" />
        <TextField source="language" />
        <ReferenceField source="acquisitionId" reference="acquisition">
          <TextField source="type" />
        </ReferenceField>

        <TextField source="auth0Id" />
        <TextField source="accountHolder" />
        <TextField source="IBAN" />
        <ReferenceField source="currentPropertyId" reference="property">
          <TextField source="address" />
        </ReferenceField>

        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="Notes">
        <RichTextField source="notes" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default ContactShow
