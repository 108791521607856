/* eslint-disable import/first */
import React from 'react'
import ReactDOM from 'react-dom'
import { Admin, Resource } from 'react-admin' // eslint-disable-line import/no-unresolved
import dataProvider from './dataProvider'
import i18nProvider from './i18nProvider'

import { Layout } from './layout'
import customRoutes from './customRoutes'

import agencyFeeClaim from './agencyFeeClaim'
import contact from './contact'
import agent from './agent'
import property from './property'
import actionTemplate from './actionTemplate'
import actor from './actor'
import airbnbClaim from './airbnbClaim'
import airbnbReceipt from './airbnbReceipt'
import claimState from './claimState'
import role from './role'
import action from './action'
import court from './court'
import mailTemplate from './mailTemplate'

import { apiUrl } from './config'

import Auth0Container from './Auth0Container'

// const env = process.env.NODE_ENV || 'development'

/*
import * as Sentry from '@sentry/browser'
import { sentryDSN, gtmId, gtmAuth, gtmPreview } from './config'
// init sentry as early as possible
if (env === 'production') {
  Sentry.init({ dsn: sentryDSN })
}
*/

import { createBrowserHistory as createHistory } from 'history'

const history = createHistory()

ReactDOM.render(
  <Auth0Container>
    {({ token, logout }) => {
      const authProvider = {
        login: (params) => Promise.resolve(),
        logout,
        checkAuth: (params) => Promise.resolve(),
        checkError: (error) => Promise.resolve(),
        getPermissions: (params) => Promise.resolve(),
      }
      return (
        <Admin
          history={history}
          dataProvider={dataProvider(apiUrl + '/crud', token)}
          i18nProvider={i18nProvider}
          title="Robin"
          authProvider={authProvider}
          layout={Layout}
          customRoutes={customRoutes}
          disableTelemetry
        >
          {(permissions) => [
            <Resource name="action" {...action} />,
            <Resource name="agencyFeeClaim" {...agencyFeeClaim} />,
            <Resource name="contact" {...contact} />,
            <Resource name="agent" {...agent} />,
            <Resource name="property" {...property} />,
            <Resource name="airbnbClaim" {...airbnbClaim} />,
            <Resource
              name="actionTemplate"
              options={{ label: 'Action Templates' }}
              {...actionTemplate}
            />,
            <Resource
              name="mailTemplate"
              options={{ label: 'Mail Templates' }}
              {...mailTemplate}
            />,
            <Resource name="role" {...role} />,
            <Resource name="actor" {...actor} />,
            <Resource name="claimState" {...claimState} />,
            <Resource name="claim" />,
            <Resource name="court" {...court} />,
            <Resource name="airbnbReceipt" {...airbnbReceipt} />,
            <Resource name="acquisition" />,
            <Resource name="airbnbReceiptQueueEntry" />,
            <Resource name="agencyFeeClaimFile" />,
          ]}
        </Admin>
      )
    }}
  </Auth0Container>,
  document.getElementById('root'),
)
