import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

const GenerateFilesDialog = (props) => {
  const { onClose, open, files } = props

  const handleClose = () => {
    onClose(false)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select File</DialogTitle>
      <List>
        {Object.values(files).map((file) => {
          return (
            <ListItem
              button
              onClick={() => handleListItemClick(file)}
              key={file.id}
            >
              <ListItemText primary={file.name} />
            </ListItem>
          )
        })}
      </List>
    </Dialog>
  )
}

export default GenerateFilesDialog
