import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  SelectInput,
  AutocompleteInput,
  TextInput,
  TabbedForm,
  FormTab,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  // useInput,
  LinearProgress,
  useGetOne,
  DateInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
} from 'react-admin'

import NotesInput from '../shared/NotesInput'
import RedirectedDeleteButton from '../shared/RedirectedDeleteButton'

import { useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'

import getRecord from '../getRecord'

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
})

const CustomToolbar = (props) => (
  <StyledToolbar {...props}>
    <SaveButton />
    <RedirectedDeleteButton id={props.record.claimId} resource="claim" />
  </StyledToolbar>
)

const SelectActions = (props) => {
  /*
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)
  console.log(props)
  */
  return null
  /*
  return (
    <TextField
      name={name}
      label={props.label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
    />
  )*/
}

const AgencyFeeClaimEdit = ({ permissions, ...props }) => {
  const record = useSelector(getRecord(props.resource, props.id))
  const { loading, loaded, data } = useGetOne('claim', record?.claimId)

  const claimLoaded = loaded && !loading && data && data.id

  return (
    <Edit {...props}>
      <TabbedForm
        initialValues={{
          interest: 0,
          claim: { ...data },
        }}
        toolbar={<CustomToolbar />}
        redirect="show"
      >
        <FormTab label="base">
          <NumberInput source="amount" />
          <NumberInput source="interest" />
          <TextInput source="amountType" />
          <BooleanInput source="approached" />
          <BooleanInput source="rentalAgreementCanceled" />

          <BooleanInput source="foundPropertyWithoutAgent" />
          <BooleanInput source="rentalAgreementCanceled" />
          <BooleanInput source="paid" />
          <BooleanInput source="impossibleToContactAgent" />
          <BooleanInput source="propertyTypeCorrect" />
          <TextInput multiline source="userExplanationSearch" />
          <DateInput source="dateOfPayment" />

          <SelectInput
            source="lastStepDone"
            choices={[
              { id: 'property', name: 'Property' },
              { id: 'realtor', name: 'Realtor' },
              { id: 'proofOfPayment', name: 'ProofOfPayment' },
              { id: 'contactDetails', name: 'contactDetails' },
              { id: 'signature', name: 'Signature' },
            ]}
          />

          <ReferenceInput source="agentId" reference="agent">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput source="propertyId" reference="property">
            <AutocompleteInput optionText="address" />
          </ReferenceInput>
          <ReferenceInput source="claim.courtId" reference="court">
            <AutocompleteInput optionText="location" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Notes">
          {claimLoaded ? (
            <NotesInput source="claim.notes" />
          ) : (
            <LinearProgress />
          )}
        </FormTab>
        <FormTab label="actions">
          <ReferenceArrayInput source="actionIds" reference="action">
            <ReferenceInput
              source="actionTemplateId"
              reference="actionTemplate"
            >
              <SelectActions />
            </ReferenceInput>
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

AgencyFeeClaimEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default AgencyFeeClaimEdit
