import React, { useState, useEffect } from 'react'
import {
  Button,
  LinearProgress,
  useGetList,
  useNotify,
  useDataProvider,
} from 'react-admin'

import { useSelector } from 'react-redux'
import AssignDialog from './AssignDialog'

import getRecords from '../getRecords'

const SendMailButton = ({ record, selectedIds, resource }) => {
  const notify = useNotify()
  const { data, loading, loaded, error } = useGetList('actor', {
    page: 1,
    perPage: 999999,
  })

  const records = useSelector(getRecords(resource))

  useEffect(() => {
    if (error) {
      notify(error.message, 'error')
    }
  }, [notify, error])

  const dataProvider = useDataProvider()

  const assign = (id, nextActionAssigneeId) =>
    dataProvider
      .update(resource, {
        id,
        data: {
          ...records[id],
          nextActionAssigneeId,
        },
      })
      .catch((error) => {
        // failure side effects go here
        notify(error.message, 'error')
      })

  // generalise for multiple records
  if (record && !selectedIds) {
    selectedIds = [record.id]
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  if (loading || !selectedIds) {
    return <LinearProgress />
  }

  const handleClose = (actor) => {
    setOpen(false)
    if (!actor) {
      return
    }
    Promise.all(selectedIds.map((id) => assign(id, actor.id))).then(() => {
      notify(`Actions assigned to ${actor.name}`)
    })
  }

  return (
    <>
      {!loading && loaded && data && (
        <AssignDialog open={open} onClose={handleClose} actors={data} />
      )}
      <Button label="Assign" onClick={handleClickOpen} />
    </>
  )
}

export default SendMailButton
