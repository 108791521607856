// in src/customRoutes.js
import React from 'react'
import { Route } from 'react-router-dom'
import { QueueTerminator314 /*, Dashboard*/ } from './routes'

const Login = () => null

export default [
  // <Route exact path="/Dashboard" component={Dashboard} />,
  <Route exact path="/QueueTerminator314" component={QueueTerminator314} />,
  <Route exact path="/login" component={Login} noLayout />,
]
