import React from 'react'
import {
  TextInput,
  SimpleForm,
  BooleanInput,
  SelectInput,
  required,
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'

export const mailtoOptions = [
  {
    id: 'client',
    name: 'klant',
  },
  {
    id: 'counterparty',
    name: 'wederpartij',
  },
]

const MailTemplateInput = (props) => (
  <SimpleForm
    redirect="show"
    initialValues={{
      signed: false,
    }}
    {...props}
  >
    <TextInput source="name" validate={[required()]} />
    <TextInput source="subject" validate={[required()]} />
    <SelectInput source="to" validate={[required()]} choices={mailtoOptions} />
    <SelectInput source="cc" choices={mailtoOptions} />
    <SelectInput source="bcc" choices={mailtoOptions} />
    <BooleanInput source="signed" />
    <RichTextInput source="body" validate={[required()]} />
  </SimpleForm>
)

export default MailTemplateInput
