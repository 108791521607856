import PeopleIcon from '@material-ui/icons/People'
import ActorList from './ActorList'
import ActorShow from './ActorShow'
import ActorEdit from './ActorEdit'
import ActorCreate from './ActorCreate'

export default {
  list: ActorList,
  show: ActorShow,
  edit: ActorEdit,
  create: ActorCreate,
  icon: PeopleIcon,
}
