/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SimpleList,
  SearchInput,
  TextField,
  TextInput,
} from 'react-admin'

// import ContactEditEmbedded from './ContactEditEmbedded'

const ContactFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="fullName" />
  </Filter>
)

const ContactBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
)

const ContactList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<ContactFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<ContactBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList primaryText={(record) => record.fullName} />
    ) : (
      <Datagrid
        rowClick={'show'}
        /*expand={<ContactEditEmbedded />}*/
        optimized
      >
        <TextField source="fullName" />
        <TextField source="email" />
        <TextField source="language" />
      </Datagrid>
    )}
  </List>
)

export default ContactList
