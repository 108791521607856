import React from 'react'
import PropTypes from 'prop-types'
import { Edit } from 'react-admin'

import Title from './ActionTemplateTitle'
import ActionTemplateInput from './ActionTemplateInput'

const ActionTemplateEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <ActionTemplateInput />
  </Edit>
)

ActionTemplateEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default ActionTemplateEdit
