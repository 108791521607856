import React from 'react'
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin'

import Title from './RoleTitle'

const RoleCreate = (props) => (
  <Create title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <BooleanInput source="allowActors" />
    </SimpleForm>
  </Create>
)

export default RoleCreate
