import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  DateField,
  ReferenceField,
} from 'react-admin'

import Title from './ActionTitle'

export const ActionShow = (props) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        {/*<TextField source="extraData" />*/}
        <ReferenceField
          link="show"
          source="nextActionAssigneeId"
          reference="actor"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link="show" source="doneById" reference="actor">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link="show" source="claimId" reference="claim">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          link="show"
          source="actionTemplateId"
          reference="actionTemplate"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link="show" source="doneByRoleId" reference="role">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <ReferenceField
          link="show"
          source="claimStateId"
          reference="claimState"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <TextField source="reminderText" />
        <DateField source="doneAt" />
        <DateField source="nextActionAt" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="Notes">
        <RichTextField source="notes" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default ActionShow
