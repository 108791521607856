/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  SimpleList,
  NumberField,
  TextField,
  DateField,
  ReferenceField,
  SelectInput,
  TextInput,
} from 'react-admin'
// import { Chip } from '@material-ui/core'
import SendMailButton from './SendMailButton'

import NestedReferenceField from '../shared/NestedReferenceField'

const AgencyFeeClaimFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    {/*
    <Chip
      source="claim.stateSystemKey:neq"
      label="Not rejected"
      defaultValue={'rejected'}
    />
    */}
    <TextInput source="name" />
    <SelectInput
      source="lastStepDone"
      choices={[
        { id: 'property', name: 'Property' },
        { id: 'realtor', name: 'Realtor' },
        { id: 'proofOfPayment', name: 'ProofOfPayment' },
        { id: 'contactDetails', name: 'contactDetails' },
        { id: 'signature', name: 'Signature' },
      ]}
    />
  </Filter>
)

const AgencyFeeClaimBulkActionButtons = (props) => (
  <>
    <BulkDeleteWithConfirmButton {...props} />
    <SendMailButton {...props} />
  </>
)

const AgencyFeeClaimList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<AgencyFeeClaimFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={<AgencyFeeClaimBulkActionButtons />}
    /* filterDefaultValues={{ claim: { 'stateSystemKey:neq': 'rejected' } }}*/
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList
        primaryText={(record) => `${record.key} (${record.state})`}
        secondaryText={(record) => (
          <ReferenceField
            source="contactId"
            reference="contact"
            basePath="contactId"
            record={record}
            link="show"
          >
            <TextField source="fullName" />
          </ReferenceField>
        )}
      />
    ) : (
      <Datagrid
        rowClick={'show'}
        /*expand={<AgencyFeeClaimEditEmbedded />}*/
        optimized
      >
        <NumberField source="id" />
        <ReferenceField
          label="key"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="key" />
        </ReferenceField>

        <NestedReferenceField
          label="Client"
          source="claimId"
          nestedSource="contactId"
          reference="claim"
          nestedReference="contact"
          link="show"
        >
          <TextField source="fullName" />
        </NestedReferenceField>
        <ReferenceField
          label="Debtor"
          source="agentId"
          reference="agent"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="amount" />

        <ReferenceField
          label="state"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="state" />
        </ReferenceField>

        <TextField source="lastStepDone" />
        <DateField source="createdAt" />
      </Datagrid>
    )}
  </List>
)

export default AgencyFeeClaimList
