import React, { useState } from 'react'
import { Title } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useQuery, Loading, Error } from 'react-admin'
import CustomForm from '../../shared/CustomForm'
import Input from './input'
import Backdrop from '@material-ui/core/Backdrop'

import { useAuth0 } from '../../auth0-spa'

const GetRecord = ({
  resource,
  record,
  baseFilter,
  filterProp,
  filterBy,
  children,
}) => {
  if (!filterProp) {
    filterProp = filterBy
  }
  const filter = baseFilter || {}
  filter[filterBy] = record[filterProp]

  const { error, loaded, loading, data } = useQuery({
    type: 'getList',
    resource,
    payload: {
      filter,
      sort: { field: 'id', order: 'asc' },
    },
  })

  if (error) {
    return <Error />
  }
  if (!loaded) {
    return <Loading />
  }

  if (!data[0]) {
    return <Typography>All receipts processed</Typography>
  }

  return (
    <>
      {React.cloneElement(children, {
        record: data[0],
        parentRecord: record,
      })}
      <Backdrop open={loading} style={{ zIndex: 99999 }}></Backdrop>
    </>
  )
}

const QueueProcessor = ({ record, parentRecord, setVersion }) => {
  return (
    <CustomForm
      setResourceTo="airbnbReceipt"
      setRecordTo={record}
      toolbar={null}
      submitOnEnter={false}
      initialValues={{
        currency: 'EUR',
        airbnbCode: '',
        amount: '',
        dateOfPayment: '',
      }}
    >
      <Input queueEntry={parentRecord} setVersion={setVersion} />
    </CustomForm>
  )
}

export const QueueTerminator314 = () => {
  // to force reloading
  const [version, setVersion] = useState(0)
  const {
    user: { auth0Id },
  } = useAuth0()
  return (
    <Card>
      <Title title="Queue Terminator 3.14" />
      <CardContent>
        <GetRecord resource="actor" record={{ auth0Id }} filterBy="auth0Id">
          <GetRecord
            resource="airbnbReceiptQueueEntry"
            filterBy="actorId"
            filterProp="id"
            baseFilter={{ done: false, skipped: false, _version: version }}
          >
            <GetRecord
              resource="airbnbReceipt"
              filterBy="id"
              filterProp="airbnbReceiptId"
            >
              <QueueProcessor setVersion={setVersion} />
            </GetRecord>
          </GetRecord>
        </GetRecord>
      </CardContent>
    </Card>
  )
}
