import PeopleIcon from '@material-ui/icons/People'
import ClaimStateList from './ClaimStateList'
import ClaimStateShow from './ClaimStateShow'
import ClaimStateEdit from './ClaimStateEdit'
import ClaimStateCreate from './ClaimStateCreate'

export default {
  list: ClaimStateList,
  show: ClaimStateShow,
  edit: ClaimStateEdit,
  create: ClaimStateCreate,
  icon: PeopleIcon,
}
