import React from 'react'
import PropTypes from 'prop-types'
import { Edit, TextInput, TabbedForm, FormTab } from 'react-admin'

import NotesInput from '../shared/NotesInput'

import Title from './CourtTitle'

const CourtEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Base">
        <TextInput source="name" />
        <TextInput source="location" />
        <TextInput source="phone" />
        <TextInput source="url" />
      </FormTab>
      <FormTab label="Notes">
        <NotesInput />
      </FormTab>
    </TabbedForm>
  </Edit>
)

CourtEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default CourtEdit
