import PeopleIcon from '@material-ui/icons/People'
import AgencyFeeClaimList from './AgencyFeeClaimList'
import AgencyFeeClaimEdit from './AgencyFeeClaimEdit'
import AgencyFeeClaimShow from './AgencyFeeClaimShow'

export default {
  list: AgencyFeeClaimList,
  show: AgencyFeeClaimShow,
  edit: AgencyFeeClaimEdit,
  icon: PeopleIcon,
}
