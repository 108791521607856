import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  FunctionField,
  LinearProgress,
  useGetOne,
  Toolbar,
  SaveButton,
} from 'react-admin'

import { useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'

import NotesInput from '../shared/NotesInput'
import RetractClaimButton from '../shared/RetractClaimButton'

import Button from '@material-ui/core/Button'
import AirbnbClaimTitle from './AirbnbClaimTitle'

import getRecord from '../getRecord'

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
})

const CustomToolbar = (props) => (
  <StyledToolbar {...props}>
    <SaveButton />
    <RetractClaimButton id={props.record.claimId} resource="claim" />
  </StyledToolbar>
)

const AirbnbClaimEdit = ({ permissions, ...props }) => {
  const record = useSelector(getRecord(props.resource, props.id))
  const { loading, loaded, data } = useGetOne('claim', record?.claimId)

  const claimLoaded = loaded && !loading && data && data.id

  return (
    <Edit title={<AirbnbClaimTitle />} {...props}>
      <TabbedForm
        initialValues={{
          interest: 0,
          claim: { ...data },
        }}
        toolbar={<CustomToolbar />}
        redirect="show"
      >
        <FormTab label="summary">
          <NumberInput source="interest" />
          <BooleanInput source="paid" />
          <ReferenceInput source="propertyId" reference="property">
            <AutocompleteInput optionText="address" />
          </ReferenceInput>
          <FunctionField
            source="googleDriveFolderLink"
            addLabel={false}
            style={{ paddingBottom: 10, display: 'block' }}
            render={(record) => (
              <Button
                variant="contained"
                target="_blank"
                href={record.googleDriveFolderLink}
              >
                Open Folder
              </Button>
            )}
          />
        </FormTab>
        <FormTab label="Notes">
          {claimLoaded ? (
            <NotesInput source="claim.notes" />
          ) : (
            <LinearProgress />
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

AirbnbClaimEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default AirbnbClaimEdit
