// in src/Menu.js
import React, { createElement } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources } from 'react-admin'
import { withRouter } from 'react-router-dom'
import LabelIcon from '@material-ui/icons/Label'
// import DashboardIcon from '@material-ui/icons/Dashboard'

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  return (
    <div>
      {/*
      <MenuItemLink
        to="/Dashboard"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      */}
      <MenuItemLink
        to="/QueueTerminator314"
        primaryText="Queue Terminator 3.14"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources
        .filter((resource) => resource.hasList)
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon && createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
