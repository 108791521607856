import React from 'react'
import PropTypes from 'prop-types'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  ReferenceField,
  DateField,
  BooleanField,
  TopToolbar,
  EditButton,
  ReferenceArrayField,
  Datagrid,
} from 'react-admin'

import GoogleDriveFileList from './GoogleDriveFileList'

import ActionsTab from '../shared/ActionsTab'
import NestedReferenceField from '../shared/NestedReferenceField'

import GenerateFileButton from './GenerateFileButton'
import SendMailButton from './SendMailButton'
import CreateMailButton from './CreateMailButton'
import Button from '@material-ui/core/Button'

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <GenerateFileButton record={data} />
    <SendMailButton record={data} />
    <CreateMailButton record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
)

const AgencyFeeClaimShow = ({ ...props }) => (
  <Show actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <ReferenceField
          label="Key"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="key" />
        </ReferenceField>
        <ReferenceField
          label="Client"
          source="contactId"
          reference="contact"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField
          label="Debtor"
          source="agentId"
          reference="agent"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Object"
          source="propertyId"
          reference="property"
          link="show"
        >
          <TextField source="address" />
        </ReferenceField>

        <TextField source="amount" />
        <TextField source="amountType" />
        <TextField source="interest" />
        <TextField source="collectionCosts" />
        <TextField source="totalCosts" />
        <ReferenceField
          label="state"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="state" />
        </ReferenceField>

        <ReferenceField
          label="allowedProxy"
          source="claimId"
          reference="claim"
          link={false}
        >
          <BooleanField source="allowedProxy" />
        </ReferenceField>
        <BooleanField source="foundPropertyWithoutAgent" />
        <BooleanField source="rentalAgreementCanceled" />
        <BooleanField source="paid" />
        <BooleanField source="impossibleToContactAgent" />
        <BooleanField source="propertyTypeCorrect" />
        <TextField source="userExplanationSearch" />
        <DateField source="dateOfPayment" />
        <TextField source="lastStepDone" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <NestedReferenceField
          label="court"
          source="claimId"
          reference="claim"
          nestedSource="courtId"
          nestedReference="court"
          link="show"
        >
          <TextField source="location" />
        </NestedReferenceField>
      </Tab>
      <Tab label="Notes">
        <ReferenceField
          label="notes"
          source="claimId"
          reference="claim"
          link={false}
        >
          <RichTextField source="notes" />
        </ReferenceField>
      </Tab>
      <Tab label="user files" path="storage">
        <ReferenceArrayField source="filesIds" reference="agencyFeeClaimFile">
          <Datagrid>
            <FunctionField
              source="fileLink"
              addLabel={false}
              render={(record) => (
                <Button
                  variant="contained"
                  target="_blank"
                  href={record.fileLink}
                >
                  Open File
                </Button>
              )}
            />
            <TextField source="fileName" />
            <TextField source="fileType" />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
      <Tab label="drive" path="drive">
        <GoogleDriveFileList addLabel label="File list" />
        <FunctionField
          source="googleDriveFolderLink"
          addLabel={false}
          style={{ paddingBottom: 10, display: 'block' }}
          render={(record) => (
            <Button
              variant="contained"
              target="_blank"
              href={record.googleDriveFolderLink}
            >
              Open Folder
            </Button>
          )}
        />
      </Tab>
      <ActionsTab />
    </TabbedShowLayout>
  </Show>
)

AgencyFeeClaimShow.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default AgencyFeeClaimShow
