import PeopleIcon from '@material-ui/icons/People'
import AirbnbReceiptList from './AirbnbReceiptList'
import AirbnbReceiptEdit from './AirbnbReceiptEdit'
// import AirbnbReceiptShow from './AirbnbReceiptShow'

export default {
  list: AirbnbReceiptList,
  //   show: AirbnbReceiptShow,
  edit: AirbnbReceiptEdit,
  icon: PeopleIcon,
}
