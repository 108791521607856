import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Confirm,
  useDataProvider,
  LinearProgress,
  Error,
  fetchStart,
  fetchEnd,
  useNotify,
} from 'react-admin'

import { apiUrl } from '../config'
import SendMailDialog from './SendMailDialog'
import { httpClient } from '../dataProvider'
import { useAuth0 } from '../auth0-spa'

const SendMailButton = ({ record, selectedIds }) => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [templates, setTemplates] = useState()

  useEffect(() => {
    const getTemplates = () =>
      dataProvider
        .getList('../mailjet/templates')
        .then((result) => {
          if (!result) {
            // request was canceled
            return
          }
          setTemplates(result.data)
          setLoading(false)
        })
        .catch((error) => {
          setError(error)
          setLoading(false)
        })

    getTemplates()
  }, [dataProvider])

  // generalise for multiple records
  if (record && !selectedIds) {
    selectedIds = [record.id]
  }

  const [open, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [templateId, setTemplateId] = useState()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const { token } = useAuth0()

  if (loading || !selectedIds) {
    return <LinearProgress />
  }
  if (error) {
    return <Error error={error} />
  }

  const handleClose = (value) => {
    setOpen(false)
    if (value) {
      setTemplateId(value.ID)
      setConfirmOpen(true)
    }
  }

  const handleConfirm = async () => {
    setConfirmOpen(false)
    dispatch(fetchStart()) // start the global loading indicator
    const requests = selectedIds.map((id) =>
      httpClient(
        token,
        `${apiUrl}/agencyFeeClaim/${id}/sendEmail?mailTemplateId=${templateId}`,
        { method: 'PUT' },
      ),
    )
    await Promise.all(requests)
    notify('Mail to agent sent.')
    dispatch(fetchEnd()) // stop the global loading indicator
  }

  return (
    <>
      {!loading && (
        <SendMailDialog
          open={open}
          onClose={handleClose}
          templates={templates}
        />
      )}
      <Confirm
        isOpen={confirmOpen}
        title="Send email"
        content="Are you sure you want to send an email?"
        onConfirm={handleConfirm}
        onClose={() => setConfirmOpen(false)}
      />
      <Button label="Send Email" onClick={handleClickOpen} />
    </>
  )
}

export default SendMailButton
