import PeopleIcon from '@material-ui/icons/People'
import MailTemplateList from './MailTemplateList'
import MailTemplateShow from './MailTemplateShow'
import MailTemplateEdit from './MailTemplateEdit'
import MailTemplateCreate from './MailTemplateCreate'

export default {
  list: MailTemplateList,
  show: MailTemplateShow,
  edit: MailTemplateEdit,
  create: MailTemplateCreate,
  icon: PeopleIcon,
}
