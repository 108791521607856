/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  SimpleList,
  NumberField,
  TextField,
  DateField,
  DateInput,
  ReferenceField,
  SelectInput,
  TextInput,
} from 'react-admin'
import SendMailButton from './SendMailButton'
import SendDefaultNoticeToAirbnbButton from './SendDefaultNoticeToAirbnbButton'
import { Chip } from '@material-ui/core'

// import AirbnbClaimEditEmbedded from './AirbnbClaimEditEmbedded'
import NestedReferenceField from '../shared/NestedReferenceField'

const pad = (input) => String(input).padStart(2, '0')

const formatDate = (input) => {
  if (!input) {
    return null
  }
  const date = new Date(input)
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate())
  )
}

const parseDate = (input) => {
  // if we add the time, it will get parsed to local timezone
  return new Date(`${input} 00:00:00`).getTime()
}

const AirbnbClaimFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="name" />
    <Chip
      source="claim.allowedProxy:isNotNull"
      label="Met proxyverklaring"
      defaultValue={true}
    />
    <Chip
      source="claim.allowedProxy:isNull"
      label="Zonder proxyverklaring"
      defaultValue={true}
    />
    <Chip
      source="claim.referredById:isNotNull"
      label="Is referred"
      defaultValue={true}
    />
    <SelectInput
      source="claim.promoCode"
      label="Promocode"
      choices={[
        { id: 'ACHMEA', name: 'Achmea' },
        { id: 'ARAG', name: 'Arag' },
        { id: 'DYME', name: 'Dyme' },
        { id: 'MKBBELANGEN', name: 'MKB Belangen' },
      ]}
    />
    <SelectInput
      source="contact.book"
      label="Boek"
      choices={[
        { id: 'appeal', name: 'appeal' },
        { id: 'stichting', name: 'stichting' },
        { id: 'servicekosten', name: 'servicekosten' },
      ]}
    />
    <DateInput
      source="claim.createdAt:gte"
      label="Vanaf"
      format={formatDate}
      parse={parseDate}
      // https://github.com/marmelab/react-admin/issues/6315
      options={{ onBlur: () => {} }}
    />
    <DateInput
      source="claim.createdAt:lt"
      label="Tot"
      format={formatDate}
      parse={parseDate}
      // https://github.com/marmelab/react-admin/issues/6315
      options={{ onBlur: () => {} }}
    />
  </Filter>
)

const AirbnbClaimBulkActionButtons = (props) => (
  <>
    <BulkDeleteWithConfirmButton {...props} />
    <SendMailButton {...props} />
    <SendDefaultNoticeToAirbnbButton {...props} />
  </>
)

const AirbnbClaimList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<AirbnbClaimFilter />}
    filterDefaultValues={{ claim: { 'allowedProxy:isNotNull': true } }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={<AirbnbClaimBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList
        primaryText={(record) => `${record.key}`}
        secondaryText={(record) => (
          <ReferenceField
            source="contactId"
            reference="contact"
            basePath="contactId"
            record={record}
            link="show"
          >
            <TextField source="fullName" />
          </ReferenceField>
        )}
      />
    ) : (
      <Datagrid
        rowClick={'show'}
        /*expand={<AirbnbClaimEditEmbedded />}*/
        optimized
      >
        <NumberField source="id" />
        <ReferenceField
          label="key"
          source="claimId"
          reference="claim"
          link={false}
        >
          <TextField source="key" />
        </ReferenceField>

        <NestedReferenceField
          label="Client"
          source="claimId"
          nestedSource="contactId"
          reference="claim"
          nestedReference="contact"
          link="show"
        >
          <TextField source="fullName" />
        </NestedReferenceField>
        <ReferenceField
          label="allowedProxy"
          source="claimId"
          reference="claim"
          link={false}
        >
          <DateField source="allowedProxy" />
        </ReferenceField>

        <DateField source="createdAt" />
      </Datagrid>
    )}
  </List>
)

export default AirbnbClaimList
