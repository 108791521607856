import PeopleIcon from '@material-ui/icons/People'
import PropertyList from './PropertyList'
import PropertyShow from './PropertyShow'
import PropertyEdit from './PropertyEdit'

export default {
  list: PropertyList,
  show: PropertyShow,
  edit: PropertyEdit,
  icon: PeopleIcon,
}
