/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  SimpleList,
  NumberField,
  TextField,
  DateField,
  ReferenceField,
  TextInput,
} from 'react-admin'

// import AirbnbReceiptEditEmbedded from './AirbnbReceiptEditEmbedded'

const AirbnbReceiptFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="name" />
  </Filter>
)

const AirbnbReceiptList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<AirbnbReceiptFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList
        primaryText={(record) => `${record.key}`}
        secondaryText={(record) => (
          <ReferenceField
            source="contactId"
            reference="contact"
            basePath="contactId"
            record={record}
            link="show"
          >
            <TextField source="fullName" />
          </ReferenceField>
        )}
      />
    ) : (
      <Datagrid
        /*expand={<AirbnbReceiptEditEmbedded />}*/
        optimized
      >
        <NumberField source="id" />
        <TextField source="status" />
        <ReferenceField
          label="Client"
          source="airbnbClaimId"
          reference="airbnbClaim"
          link="show"
        >
          <ReferenceField source="contactId" reference="contact" link="show">
            <TextField source="fullName" />
          </ReferenceField>
        </ReferenceField>
        <DateField source="createdAt" />
      </Datagrid>
    )}
  </List>
)

export default AirbnbReceiptList
