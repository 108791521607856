import React, { cloneElement } from 'react'
import {
  TextInput,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
} from 'react-admin'

// TODO: figure out how to allow multiple children
const HideWhenTransparent = ({ children, ...rest }) => (
  <FormDataConsumer {...rest}>
    {({ formData, ...other }) => {
      if (!formData.transparent) {
        return cloneElement(children, other)
      }
    }}
  </FormDataConsumer>
)

const ActionTemplateInput = (props) => (
  <SimpleForm
    redirect="show"
    initialValues={{
      isInitialState: false,
      isFinalState: false,
      transparent: false,
    }}
    {...props}
  >
    <TextInput source="name" />
    <TextInput source="systemKey" />
    <BooleanInput source="transparent" />
    <SelectInput
      source="claimType"
      choices={[
        { id: 'VSPA', name: 'Agency Fee Claim' },
        { id: 'ABNB', name: 'Airbnb Claim' },
      ]}
    />
    <HideWhenTransparent>
      <ReferenceInput source="claimStateId" reference="claimState">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </HideWhenTransparent>

    <HideWhenTransparent>
      <BooleanInput source="isFinalState" />
    </HideWhenTransparent>
    {/* TODO: extraFields */}

    <TextInput multiline source="description" />
    {/* TODO: duration input */}

    <HideWhenTransparent>
      <TextInput source="defaultNextActionDelay" />
    </HideWhenTransparent>

    <HideWhenTransparent>
      <ReferenceArrayInput
        source="possibleNextActionsIds"
        reference="actionTemplate"
        label="Possible Actions"
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </HideWhenTransparent>

    <HideWhenTransparent>
      <TextInput source="reminderText" />
    </HideWhenTransparent>

    <HideWhenTransparent>
      <ReferenceArrayInput
        source="canBeDoneByRolesIds"
        reference="role"
        label="Can Be Done By"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </HideWhenTransparent>
  </SimpleForm>
)

export default ActionTemplateInput
