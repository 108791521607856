import PeopleIcon from '@material-ui/icons/People'
import ActionList from './ActionList'
import ActionShow from './ActionShow'
import ActionEdit from './ActionEdit'
// import ActionCreate from './ActionCreate'

export default {
  list: ActionList,
  show: ActionShow,
  edit: ActionEdit,
  /*
  create: ActionCreate,
  */
  icon: PeopleIcon,
  options: {
    label: 'Takenlijst',
  },
}
