import React, { useState } from 'react'
import {
  Button,
  useUpdate,
  Loading,
  useNotify,
  // required,
} from 'react-admin'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { useFormState, useForm } from 'react-final-form'

import ReasonDialog from './ReasonDialog'

const rejectionReasons = [
  'De boeking is te lang geleden (meer dan 3 jaar voor indiendatum)',
  'De boeking is niet op jouw naam',
  'De boeking is gecancelled of is geen huurwoning',
  'De boeking is dubbel ingediend',
  'Er zijn geen servicekosten betaald',
]

const pendingReasons = [
  'Het bestand is onleesbaar (te klein of te lage kwaliteit)',
  'Het bestand is niet laadbaar (0 bytes)',
  'Bevestigingscode ontbreekt op de factuur',
  'Specificering van de servicekosten ontbreekt op de factuur',
  'De reserveringsdatum mist op de factuur',
  'Meerdere gegevens missen op de factuur (bevestigingscode, bedrag van de servicekosten of de reserveringsdatum)',
  'Munteenheid ontbreekt',
  'Anders',
]

const receiptPath = 'airbnbReceipt'
const queuePath = 'airbnbReceiptQueueEntry'

const Actions = ({ queueEntry, record, setVersion, setLoading }) => {
  const { values, hasValidationErrors } = useFormState()
  const { submit } = useForm()
  const [lastUpdated, setLastUpdated] = useState(null)
  const notify = useNotify()
  const [rejectionDialog, setRejectionDialogOpen] = useState(false)
  const [pendingDialog, setPendingDialogOpen] = useState(false)

  const [update, { loading: saving }] = useUpdate(
    receiptPath,
    record.id,
    {}, // set by the caller
    record,
  )

  const save = (data, onSuccess, onFailure) =>
    update({ payload: { data } }, { onSuccess, onFailure })

  const [setQueueDone, queueDoneStatus] = useUpdate(
    queuePath,
    queueEntry.id,
    { ...queueEntry, done: true },
    queueEntry,
  )
  const [setQueueSkip, queueSkipStatus] = useUpdate(
    queuePath,
    queueEntry.id,
    { ...queueEntry, skipped: true },
    queueEntry,
  )

  if (queueDoneStatus.loading || queueSkipStatus.loading || saving) {
    return <Loading />
  }

  // check if we processed an update
  const lastDoneId = queueDoneStatus.data?.id || -1
  const lastSkippedId = queueSkipStatus.data?.id || -1
  const lastUpdatedId = Math.max(lastDoneId, lastSkippedId)
  if (lastUpdated < lastUpdatedId) {
    setVersion((v) => v + 1)
    // id is sorted asc, so we can take the max
    setLastUpdated(lastUpdatedId)
    setLoading(false)
  }

  const handleAction = (status, noValidation, skip, extraValues) => {
    if (skip) {
      setLoading(true)
      return setQueueSkip()
    }
    if (!noValidation && hasValidationErrors) {
      // we will not actually submit, but this way errors are shown
      return submit()
    }
    setLoading(true)
    const valuesToSubmit = { ...values, ...extraValues }
    if (status) {
      valuesToSubmit.status = status
    }
    save(
      valuesToSubmit,
      () => {
        setQueueDone()
      },
      (err) => {
        console.log(err.body.message)
        notify(err.body.message, 'warning')
        setLoading(false)
      },
    )
  }

  const handleRejectionDialogClose = (value) => {
    setRejectionDialogOpen(false)
    if (value === false) {
      return
    }
    handleAction('rejected', true, false, { rejectionReason: value })
  }

  const handlePendingDialogClose = (value) => {
    setPendingDialogOpen(false)
    if (value === false) {
      return
    }
    handleAction('pending', true, false, { rejectionReason: value })
  }

  return (
    <Paper
      variant="outlined"
      style={{ padding: '2em 1em', marginBottom: '1em', width: '100%' }}
    >
      <Typography color="textSecondary" gutterBottom>
        Maak een keuze
      </Typography>
      <Grid container>
        <Box>
          <Button
            size="small"
            label="Accept"
            variant="contained"
            style={{ marginRight: '2em' }}
            onClick={() => handleAction('accepted')}
          />
          <Button
            size="small"
            label="Pending"
            variant="outlined"
            style={{ marginRight: '2em' }}
            onClick={() => setPendingDialogOpen(true)}
          />
          <Button
            size="small"
            label="Reject"
            variant="outlined"
            style={{ marginRight: '2em' }}
            onClick={() => setRejectionDialogOpen(true)}
          />
          <Button
            size="small"
            label="Skip"
            variant="outlined"
            onClick={() => handleAction(null, true, true)}
          />
        </Box>
      </Grid>
      <ReasonDialog
        open={pendingDialog}
        reasons={pendingReasons}
        onClose={(value) => handlePendingDialogClose(value)}
      />
      <ReasonDialog
        open={rejectionDialog}
        reasons={rejectionReasons}
        onClose={(value) => handleRejectionDialogClose(value)}
      />
    </Paper>
  )
}

export default Actions
