import PeopleIcon from '@material-ui/icons/People'
import ActionTemplateList from './ActionTemplateList'
import ActionTemplateShow from './ActionTemplateShow'
import ActionTemplateEdit from './ActionTemplateEdit'
import ActionTemplateCreate from './ActionTemplateCreate'

export default {
  list: ActionTemplateList,
  show: ActionTemplateShow,
  edit: ActionTemplateEdit,
  create: ActionTemplateCreate,
  icon: PeopleIcon,
}
