import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
} from 'react-admin'

import Title from './ActorTitle'

const ActorShow = (props) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="auth0Id" />
      <ReferenceField source="roleId" reference="role">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <TextField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

export default ActorShow
