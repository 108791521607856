import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

const CreateMailDialog = (props) => {
  const { onClose, selectedValue, open, templates } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select Template</DialogTitle>
      <List>
        {Object.values(templates).map((template) => {
          return (
            <ListItem
              button
              onClick={() => handleListItemClick(template)}
              key={template.id}
            >
              <ListItemText primary={template.name} />
            </ListItem>
          )
        })}
      </List>
    </Dialog>
  )
}

export default CreateMailDialog
