import React from 'react'
import {
  Edit,
  SelectInput,
  TextInput,
  TabbedForm,
  DateInput,
  FormTab,
  ReferenceInput,
} from 'react-admin'

import NotesInput from '../shared/NotesInput'

import Title from './ActionTitle'

export const ActionEdit = (props) => (
  <Edit title={<Title />} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Base">
        {/*<TextInput source="extraData" />*/}
        <ReferenceInput source="nextActionAssigneeId" reference="actor">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="doneById" reference="actor">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="actionTemplateId" reference="actionTemplate">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="doneAt" />
        <DateInput source="nextActionAt" />
        <TextInput source="updatedAt" />
        <ReferenceInput source="doneByRoleId" reference="role">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <ReferenceInput source="claimStateId" reference="claimState">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="description" />
        <TextInput source="reminderText" />
      </FormTab>
      <FormTab label="Notes">
        <NotesInput />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default ActionEdit
