import React, { useState, useEffect } from 'react'

import { apiUrl } from './config'
import { Auth0Provider, useAuth0 } from './auth0-spa'
import { redirectUri } from './config'
import { Loading } from 'react-admin'
import { httpClient } from './dataProvider'

const LoadScreen = () => (
  <Loading loadingPrimary="Authenticating" loadingSecondary="Please wait" />
)

class DontReload extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    return this.props.children
  }
}

const WaitForLoading = React.memo((props) => {
  const { loading, isAuthenticated, loginWithRedirect, token, logout } =
    useAuth0()
  const [permissions, setPermissions] = useState()

  const storedToken = localStorage.getItem('token')

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const {
          json: { result },
        } = await httpClient(storedToken, `${apiUrl}/currentUser/permissions`)
        setPermissions(result)
      } catch (e) {
        // 500 as that is what we get back on a malformed token
        if (e.status === 500 || e.status === 401) {
          localStorage.removeItem('token')
          loginWithRedirect()
        }
      }
    }
    if (storedToken) {
      getPermissions()
    }
  }, [storedToken, loginWithRedirect])

  if (!storedToken) {
    if (token) {
      localStorage.setItem('token', token)
    } else {
      // if auth0 is loading, we can't check if we're authenticated
      if (loading) {
        return <LoadScreen />
      } else if (!isAuthenticated) {
        loginWithRedirect()
        return null
      }
    }
  }

  // we are authenticated, but permissions might still be loading
  if (loading || !permissions) {
    return <LoadScreen />
  } else if (!permissions?.claim?.read) {
    return (
      <span>
        Permission denied.{' '}
        <button onClick={() => logout({ returnTo: redirectUri })}>
          Logout
        </button>
      </span>
    )
  }

  // get permissions
  return (
    <DontReload>
      {props.children({
        token: storedToken,
        permissions,
        logout: () => logout({ returnTo: redirectUri }),
      })}
    </DontReload>
  )
})

export default (props) => {
  return (
    <Auth0Provider>
      <WaitForLoading>{props.children}</WaitForLoading>
    </Auth0Provider>
  )
}
