import PeopleIcon from '@material-ui/icons/People'
import CourtList from './CourtList'
import CourtShow from './CourtShow'
import CourtEdit from './CourtEdit'

export default {
  list: CourtList,
  show: CourtShow,
  edit: CourtEdit,
  icon: PeopleIcon,
}
