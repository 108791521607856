import React from 'react'
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin'

import Title from './ClaimStateTitle'

const ClaimStateShow = (props) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <TextField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

export default ClaimStateShow
