import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  SelectInput,
  TabbedForm,
  FormTab,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  FunctionField,
} from 'react-admin'

import Button from '@material-ui/core/Button'
import AirbnbReceiptTitle from './AirbnbReceiptTitle'

const AirbnbReceiptEdit = ({ permissions, ...props }) => (
  <Edit title={<AirbnbReceiptTitle />} {...props}>
    <TabbedForm
      initialValues={{
        interest: 0,
      }}
    >
      <FormTab label="summary">
        <NumberInput source="interest" />
        <BooleanInput source="allowedProxy" />
        <BooleanInput source="paid" />
        <ReferenceInput source="propertyId" reference="property">
          <SelectInput optionText="address" />
        </ReferenceInput>
        <ReferenceInput source="contactId" reference="contact">
          <SelectInput optionText="fullName" />
        </ReferenceInput>
        <NumberInput source="lastParserVersionRun" />
      </FormTab>
      <FormTab label="Notes">
        <TextInput multiline source="notes" fullWidth rows={12} />
      </FormTab>
      <FormTab label="Receipts">
        <FunctionField
          source="googleDriveFolderLink"
          addLabel={false}
          style={{ paddingBottom: 10, display: 'block' }}
          render={(record) => (
            <Button
              variant="contained"
              target="_blank"
              href={record.googleDriveFolderLink}
            >
              Open Folder
            </Button>
          )}
        />
        <ArrayInput source="receipts">
          <SimpleFormIterator disableAdd disableRemove>
            <TextInput source="googleDriveFileId" disabled label="File Id" />
            <TextInput source="airbnbCode" label="Airbnb confirmation Id" />
            <TextInput source="amount" label="Amount" />
            <DateInput source="dateOfPayment" label="Date of payment" />
            <SelectInput
              source="status"
              choices={[
                { id: 'accepted', name: 'Accepted' },
                { id: 'pending', name: 'Pending' },
                { id: 'rejected', name: 'Rejected' },
              ]}
              label="Status"
            />
            <TextInput source="rejectionReason" label="Reason for rejection" />
            <BooleanInput
              source="succesfullyParsed"
              label="Succesfully parsed"
            />
            <BooleanInput
              source="succesfullyConverted"
              disabled
              label="Succesfully converted"
            />
            <FunctionField
              source="googleDriveFolderLink"
              addLabel={false}
              render={(record) => (
                <Button
                  variant="contained"
                  target="_blank"
                  href={`https://drive.google.com/file/d/${record.googleDriveFileId}/view`}
                >
                  Open File
                </Button>
              )}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)

AirbnbReceiptEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default AirbnbReceiptEdit
