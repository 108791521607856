import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin'

import Title from './ActorTitle'

const ActorEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={required()} />
      <TextInput source="auth0Id" />
      <ReferenceInput
        source="roleId"
        reference="role"
        filter={{ allowActors: true }}
      >
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

ActorEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default ActorEdit
