import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  SelectInput,
  TextInput,
  SimpleForm,
  ReferenceInput,
} from 'react-admin'

import Title from './PropertyTitle'

const PropertyEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="street" />
      <TextInput source="houseNumber" />
      <TextInput source="city" />
      <TextInput source="zipcode" />
      <TextInput source="owner" />
      <ReferenceInput source="agentId" reference="agent">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

PropertyEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default PropertyEdit
