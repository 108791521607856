import PeopleIcon from '@material-ui/icons/People'
import AirbnbClaimList from './AirbnbClaimList'
import AirbnbClaimEdit from './AirbnbClaimEdit'
import AirbnbClaimShow from './AirbnbClaimShow'

export default {
  list: AirbnbClaimList,
  show: AirbnbClaimShow,
  edit: AirbnbClaimEdit,
  icon: PeopleIcon,
}
