import React, { Children, cloneElement } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  ReferenceField,
  DateField,
  ReferenceArrayField,
  getResourceLinkPath,
  Datagrid,
} from 'react-admin'

import ActionInput from './ActionInput'
import CustomForm from './CustomForm'

const RoleNameOrDoneByField = (props) => (
  <FunctionField
    render={(record) => {
      if (!record.doneById) {
        return (
          <ReferenceField
            source="doneByRoleId"
            reference="role"
            link="show"
            {...props}
            basePath="/role"
          >
            <TextField {...props} source="name" />
          </ReferenceField>
        )
      }
      return (
        <ReferenceField
          source="doneById"
          reference="actor"
          link="show"
          {...props}
          basePath="/actor"
        >
          <TextField source="name" />
        </ReferenceField>
      )
    }}
    {...props}
  />
)

const Sort = ({ by, transform, children, ...props }) => {
  props.ids.sort((i, j) => {
    // check if exists so we don't get a "cannot read from undefined" error
    if (!props.data[i] || !props.data[j]) {
      return 0
    }
    const a = transform(props.data[i][by])
    const b = transform(props.data[j][by])
    return a - b
  })
  return cloneElement(Children.only(children), props)
}

const ActionsTab = (props) => {
  const history = useHistory()

  return (
    <Tab label="actions" {...props}>
      <ReferenceField
        label="Actions"
        source="claimId"
        reference="claim"
        link={false}
      >
        <ReferenceArrayField source="actionsIds" reference="action">
          <Sort by="doneAt" transform={(input) => new Date(input)}>
            <Datagrid
              rowClick={(id) => {
                history.push(
                  getResourceLinkPath({
                    ...props,
                    reference: 'action',
                    record: { id },
                    link: 'show',
                    source: 'id',
                  }),
                )
              }}
            >
              <TextField source="name" sortable={false} />
              <RoleNameOrDoneByField label="Done by" />
              <RichTextField source="notes" sortable={false} />
              <DateField source="doneAt" sortable={false} />
            </Datagrid>
          </Sort>
        </ReferenceArrayField>
      </ReferenceField>
      <CustomForm setResourceTo="action" setRecordToProp="claimId">
        <ActionInput />
      </CustomForm>
    </Tab>
  )
}

export default ActionsTab
