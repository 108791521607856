import React from 'react'
import { DeleteButton } from 'react-admin'

const RedirectedDeleteButton = (props) => (
  <DeleteButton
    {...props}
    record={{ id: props.record.claimId }}
    resource="claim"
  />
)

export default RedirectedDeleteButton
