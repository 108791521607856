import React, { useState } from 'react'
// import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import {
  Button,
  Confirm,
  fetchStart,
  fetchEnd,
  useNotify,
  useRefresh,
} from 'react-admin'

import { apiUrl } from '../config'
import { httpClient } from '../dataProvider'
import { useAuth0 } from '../auth0-spa'

import { styled } from '@material-ui/core/styles'

const RedButton = styled(Button)({
  color: '#f44336',
})

const RetractClaimButton = ({ id }) => {
  const dispatch = useDispatch()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const { token } = useAuth0()

  const handleClickOpen = () => {
    setConfirmOpen(true)
  }

  const retractClaim = async () => {
    setConfirmOpen(false)
    dispatch(fetchStart()) // start the global loading indicator
    await httpClient(token, `${apiUrl}/claim/${id}/retract`, {
      method: 'POST',
    })
    notify('Claim retracted')
    // refresh the view so actions get updated
    refresh()
    dispatch(fetchEnd()) // stop the global loading indicator
  }

  return (
    <>
      <Confirm
        isOpen={confirmOpen}
        title="Send email"
        content="Are you sure you want to retract this claim?"
        onConfirm={retractClaim}
        onClose={() => setConfirmOpen(false)}
      />
      <RedButton label="Retract claim" onClick={handleClickOpen} />
    </>
  )
}

export default RetractClaimButton
