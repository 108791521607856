import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

const AssignDialog = (props) => {
  const { onClose, selectedValue, open, actors } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Assign to</DialogTitle>
      <List>
        {Object.values(actors)
          .filter(({ isSystemUser }) => !isSystemUser)
          .map((actor) => {
            return (
              <ListItem
                button
                onClick={() => handleListItemClick(actor)}
                key={actor.name}
              >
                <ListItemText primary={actor.name} />
              </ListItem>
            )
          })}
      </List>
    </Dialog>
  )
}

export default AssignDialog
