/* eslint react/jsx-key: off */
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SimpleList,
  SearchInput,
  TextField,
  TextInput,
} from 'react-admin'

// import AgentEditEmbedded from './AgentEditEmbedded'

const AgentFilter = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="fullName" />
    <SearchInput source="q" alwaysOn />
  </Filter>
)

const AgentBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
)

const AgentList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<AgentFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<AgentBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
      <SimpleList primaryText={(record) => record.name} />
    ) : (
      <Datagrid
        rowClick={'show'}
        /*expand={<AgentEditEmbedded />}*/
        optimized
      >
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="entity" />
      </Datagrid>
    )}
  </List>
)

export default AgentList
