import React from 'react'
import PropTypes from 'prop-types'
import { Edit, TextInput, TabbedForm, FormTab } from 'react-admin'

import Title from './AgentTitle'

import NotesInput from '../shared/NotesInput'

const AgentEdit = ({ permissions, ...props }) => (
  <Edit title={<Title />} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Base">
        <TextInput source="name" />
        <TextInput source="street" />
        <TextInput source="houseNumber" />
        <TextInput source="city" />
        <TextInput source="zipcode" />
        <TextInput source="email" />
        <TextInput source="entity" />
        <TextInput source="feeType" />
        <TextInput source="website" />
        <TextInput source="kvkNumber" />
      </FormTab>
      <FormTab label="Notes">
        <NotesInput />
      </FormTab>
    </TabbedForm>
  </Edit>
)

AgentEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default AgentEdit
